<template>
  <div class="container profileDashboard">
    <ProfileSidebar />
    <section class="">
      <q-banner class="bg-orange-3" v-if="user && user.has_personalized_password == '0'">
        Your account is still using a temporary password. Please go to Settings and Change Password.
      </q-banner>
      <q-form>
        <div class="input-field">
            <div class="personalInfoSection">
              <div
                class="q-pt-md q-pb-xs text-h6 text-black text-weight-medium text-center"
              >
                <h5>{{ user.first_name }} {{ user.last_name }}</h5>
              </div>

              <div class="q-pa-lg text-center">
                <q-avatar size="100px">
                  <img :src="form.photo_file.current ?? require('@/assets/images/default_profile.jpg')" style="object-fit: cover" />
                </q-avatar>
              </div>
            </div>
            <div class="personalInfoSection">
              <q-file
                  color="secondary"
                  bg-color="white"
                  outlined
                  v-model="form.photo_file.data"
                  label="Add / Change Profile Photo"
                  dense
                  class="cb-input"
                  :error="form.photo_file.error != null"
                  :error-message="form.photo_file.error"
                  @clear="clearInputFile('photo_file')"
                  @input="renderImagePreview($event, 'photo_file')"
                >
              </q-file>
            </div>
            <div class="personalInfoSection">
              <div
                class="q-pt-md q-pb-xs text-h6 text-accent text-weight-medium"
              >
                Account Information
              </div>
              <div class="subtitle1 q-pt-xs q-pb-none">First Name</div>
              <q-input
                v-model="form.first_name.data"
                outlined
                class="cb-input"
                placeholder="First Name"
                :error="form.first_name.error != null"
                :error-message="form.first_name.error"
              />

              <div class="subtitle1 q-pt-sm q-pb-none">Last Name</div>
              <q-input
                v-model="form.last_name.data"
                outlined
                class="cb-input"
                placeholder="Last Name"
                :error="form.last_name.error != null"
                :error-message="form.last_name.error"
              />

              <div class="subtitle1 q-pt-sm q-pb-none">Address</div>
              <q-input
                v-model="form.address.data"
                outlined
                class="cb-input"
                placeholder="Address"
                :error="form.address.error != null"
                :error-message="form.address.error"
              />

              <div class="subtitle1 q-pt-sm q-pb-none">Date of Birth</div>
              <div class="">
                <q-input
                  outlined
                  v-model="form.date_of_birth.data"
                  class="cb-input"
                  mask="date"
                  placeholder="YYYY/MM/DD"
                  :error="form.date_of_birth.error != null"
                  :error-message="form.date_of_birth.error"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        cover
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date v-model="form.date_of_birth.data">
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>

              <div class="subtitle1 q-pt-xs q-pb-none">Email Address</div>
              <q-input
                v-model="user.email"
                outlined
                class="cb-input"
                readonly
              >
                <template v-slot:append>
                  <q-icon name="check_circle" class="text-positive"/>
                </template>
              </q-input>
              <div class="subtitle1 q-pt-xs q-pb-none">Mobile Number</div>
                <q-input
                  v-model="form.mobile_number.data"
                  outlined
                  class="cb-input"
                  placeholder="9XXXXXXXXX"
                  prefix="+63"
                  maxlength="10"
                  :error="form.mobile_number.error != null"
                  :error-message="form.mobile_number.error"
                >
                  <template v-slot:append>
                    <q-icon name="check_circle" class="text-positive" v-if="user.mobile_number_verified_at != null && userProperties.is_mobile_number_valid == true"/>
                    <q-icon name="error" class="text-negative" v-if="user.mobile_number_verified_at === null && userProperties.is_mobile_number_valid == false"/>
                    <q-icon name="error" class="text-warning" v-if="user.mobile_number_verified_at === null && userProperties.is_mobile_number_valid == true"/>
                  </template>
                </q-input>
                <q-banner class="bg-warning text-white" v-if="user.mobile_number_verified_at === null && userProperties.is_mobile_number_valid == true">
                  Please verify your mobile number by clicking the Verify Mobile Number button below.
                  <template v-slot:action>
                    <q-btn flat color="white" icon="phonelink_lock" @click="showOTPRequest = true" label="Verify Mobile Number" />
                  </template>
                </q-banner>
              <div
                class="q-pt-md q-pb-xs text-h6 text-accent text-weight-medium"
              >
                Driver's License
              </div>
              <div class="subtitle1 q-pt-xs q-pb-none">Driver's License Number</div>
                <q-input
                  v-model="form.license_number.data"
                  outlined
                  class="cb-input"
                />
              <div>
              <div class="subtitle1 q-pt-xs q-pb-none">Driver's License Front Photo</div>
              <q-file
                color="secondary"
                bg-color="white"
                outlined
                v-model="form.dl_front.data"
                :label="dlFrontLabel"
                dense
                clearable
                class="cb-input"
                :error="form.dl_front.error != null"
                :error-message="form.dl_front.error"
                @input="renderImagePreview($event, 'dl_front')"
                @clear="clearInputFile('dl_front')"
              >
                  <template v-slot:prepend>
                    <img :src="form.dl_front.current" style="width: auto; max-height: 80%">
                  </template>
              </q-file>
              <div class="subtitle1 q-pt-xs q-pb-none">Driver's License Back Photo</div>
                <q-file
                  color="secondary"
                  bg-color="white"
                  outlined
                  v-model="form.dl_back.data"
                  :label="dlBackLabel"
                  clearable
                  dense
                  class="cb-input"
                  :error="form.dl_back.error != null"
                  :error-message="form.dl_back.error"
                  @clear="clearInputFile('dl_back')"
                  @input="renderImagePreview($event, 'dl_back')"
                >
                  <template v-slot:prepend>
                    <img :src="form.dl_back.current" style="width: auto; max-height: 80%">
                  </template>
                </q-file>
              <div class="subtitle1 q-pt-xs q-pb-none">Driver's License Photo with Owner</div>
                <q-file
                  color="secondary"
                  bg-color="white"
                  outlined
                  clearable
                  v-model="form.dl_selfie.data"
                  :label="dlSelfieLabel"
                  dense
                  class="cb-input"
                  :error="form.dl_selfie.error != null"
                  :error-message="form.dl_selfie.error"
                  @clear="clearInputFile('dl_selfie')"
                  @input="renderImagePreview($event, 'dl_selfie')"
                >
                  <template v-slot:prepend>
                    <img :src="form.dl_selfie.current" style="width: auto; max-height: 80%">
                  </template>
                </q-file>
                <q-btn
                  class="btn-primary"
                  color="primary"
                  label="Save Changes"
                  :loading="isLoading"
                  icon="save"
                  @click="save()"
                  :disable="!hasChanges"
                />
                <q-btn class="btn-primary" v-if="hasChanges" @click="handleUndoChanges()" color="primary" label="Undo Changes" icon="refresh"/>
                <q-btn class="btn-primary" color="secondary" label="Back" icon="arrow_left" to="/search" />
                <div class="q-mb-lg">
                  <div
                    class="q-pt-md q-pb-xs q-mb-sm q-mt-md text-h6 text-accent text-weight-medium"
                  >
                    Account Deletion
                  </div>
                  <p>You can delete your account from our system removing your details and anything you upload.</p>
                  <q-btn class="btn-primary" color="warning" label="Process Account Deletion" icon-right="arrow_right" to="/account-deletion"/>
                </div>
              </div>
            </div>
        </div>

      </q-form>
    </section>
    <ModalOTPRequest :shown="showOTPRequest" v-on:close-dialog="showOTPRequest = !showOTPRequest"/>
  </div>
</template>
<script>
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { sizeInBase64, validateMobileNumber } from "@/validations/index";
import ProfileSidebar from "../components/Profile/ProfileSidebar.vue";
import ModalOTPRequest from "@/components/ModalOTPRequest.vue";
import { notify } from "@/utils/notification";

export default {
  components: {
    ProfileSidebar,
    ModalOTPRequest
  },
  setup() {
    const isLoading = ref(false);

    // Initialize the store.
    const store = useStore();

    // Get the user data from the store.
    const user = ref(store.state.user.data);
  
    // Get the user local properties from the store.
    const userProperties = ref(store.state.user.properties);

    // Create a form of the user that copies the current user data.
    var form = ref(getUserData());

    const showOTPRequest = ref(false);
    const dlFrontLabel = ref('Upload License Photo');
    const dlBackLabel = ref('Upload License Photo');
    const dlSelfieLabel = ref('Upload Selfie Photo');
    initializeLicenseFields();
    const hasChanges = ref(false);
    const computedValue = computed(() => {
      return form.value;
    });

    watch(computedValue, () => {
      hasChanges.value = true;
    }, {deep: true, immediate: false});

    function save() {
      resetAllErrors();
      isLoading.value = true;
      let filesToProcess = [];
      let errors = 0;

      if (form.value.dl_front.data) {
        filesToProcess.push(getBase64(form.value.dl_front.data).then((data) => {
          if (sizeInBase64(data) > 3) {
            form.value.dl_front.error = 'The maximum file size for license upload is 3MB.';
            errors++;
          } else {
            form.value.dl_front.b64 = data;
          }
        }));
      }
      if (form.value.dl_back.data) {
        filesToProcess.push(getBase64(form.value.dl_back.data).then((data) => {
          if (sizeInBase64(data) > 3) {
            form.value.dl_back.error = 'The maximum file size for license upload is 3MB.';
            errors++;
          } else {
            form.value.dl_back.b64 = data;
          }
        }));
      }
      if (form.value.dl_selfie.data) {
        filesToProcess.push(getBase64(form.value.dl_selfie.data).then((data) => {
          if (sizeInBase64(data) > 3) {
            form.value.dl_selfie.error = 'The maximum file size for license upload is 3MB.';
            errors++;
          } else {
            form.value.dl_selfie.b64 = data;
          }
        }));
      }
      if (form.value.photo_file.data) {
        filesToProcess.push(getBase64(form.value.photo_file.data).then((data) => {
          if (sizeInBase64(data) > 3) {
            form.value.photo_file.error = 'The maximum file size for license upload is 3MB.';
            errors++;
          } else {
            form.value.photo_file.b64 = data;
          }
        }));
      }
    
      Promise.allSettled(filesToProcess).then(() => {
        if (form.value.first_name.data.length == 0) {
          form.value.first_name.error = 'First Name is required.';
          errors++;
        }

        if (form.value.last_name.data.length == 0) {
          form.value.last_name.error = 'Last Name is required.';
          errors++;
        }

        if (validateMobileNumber(form.value.mobile_number.data) !== true) {
          form.value.mobile_number.error = validateMobileNumber(form.value.mobile_number.data);
          errors++;
        }

        if (errors > 0) {
          isLoading.value = false;
          notify('negative', 'Please check on any validation errors.');
          return;
        }
  
        const payload = {
          id: user.value.id,
          first_name: form.value.first_name.data,
          last_name: form.value.last_name.data,
          address: form.value.address.data,
          license_number: form.value.license_number.data,
          date_of_birth: form.value.date_of_birth.data,
          mobile_number: form.value.mobile_number.data,
          photo_file: form.value.photo_file.b64 ?? null,
          dl_front: form.value.dl_front.b64 ?? null,
          dl_back: form.value.dl_back.b64 ?? null,
          dl_selfie: form.value.dl_selfie.b64 ?? null,
        }

        store.dispatch("user/update", payload).then((response) => {
          store.commit('user/setUserData', response.data.data.user)
          store.commit("user/validateMobileNumber");
          hasChanges.value = false;
          form.value = getUserData();
          initializeLicenseFields();
          isLoading.value = false;
          notify('positive', 'Your profile has been saved successfully.');
        }).catch((e) => {
          if (e.response) {
            switch(e.response.status) {
              case 400:
                renderAllErrors(e.response.data.data.errors)
                break;
            }
          }
          isLoading.value = false;
        })
      })
    }

    function getBase64(file) {
      if (!file) {
        return;
      }
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    function renderAllErrors(validationErrors) {
      for (const key in validationErrors) {
        switch (key) {
          case 'dl_front':
            form.value.dl_front.error = validationErrors[key];
            break;
          case 'dl_back':
            form.value.dl_back.error = validationErrors[key];
            break;
          case 'dl_selfie':
            form.value.dl_selfie.error = validationErrors[key];
            break;
          case 'photo_file':
            form.value.photo_file.error = validationErrors[key];
            break;
          case 'first_name':
            form.value.first_name.error = validationErrors[key];
            break;
          case 'last_name':
            form.value.last_name.error = validationErrors[key];
            break;
          case 'mobile_number':
            form.value.mobile_number.error = validationErrors[key];
            break;
          case 'date_of_birth':
            form.value.date_of_birth.error = validationErrors[key];
            break;
          case 'license_number':
            form.value.license_number.error = validationErrors[key];
            break;                         
        }
      }
    }

    function resetAllErrors() {
      form.value.dl_front.error = null;
      form.value.dl_back.error = null;
      form.value.dl_selfie.error = null;
      form.value.photo_file.error = null;
      form.value.first_name.error = null;
      form.value.last_name.error = null;
      form.value.mobile_number.error = null;
      form.value.date_of_birth.error = null;
      form.value.license_number.error = null;
    }

    function clearInputFile(target) {
      switch (target) {
        case 'dl_front':
          form.value.dl_front.current = null;
          form.value.dl_front.b64 = 'clear';
          dlFrontLabel.value = 'Upload License Photo';
          
          break;
        case 'dl_back':
          form.value.dl_back.current = null;
          form.value.dl_back.b64 = 'clear';
          dlBackLabel.value = 'Upload License Photo';
          break;
        case 'dl_selfie':
          form.value.dl_selfie.current = null;
          form.value.dl_selfie.b64 = 'clear';
          dlSelfieLabel.value = 'Upload License Photo';
          break;
        case 'photo_file':
          form.value.photo_file.current = null;
          form.value.photo_file.b64 = 'clear';
          break;
      }
    }

    function getUserData() {
      user.value = store.state.user.data;

      return {
        first_name: {
          data: user.value.first_name,
          error: null
        },
        last_name: {
          data: user.value.last_name,
          error: null
        },
        address: {
          data: user.value.address,
          error: null
        },
        date_of_birth: {
          data: user.value.date_of_birth,
          error: null
        },
        mobile_number: {
          data: user.value.mobile_number,
          error: null
        },
        license_number: {
          data: user.value.license_number,
          error: null
        },
        dl_front: {
          data: null,
          error: null,
          b64: null,
          current: user.value.license_front_photo_file
        },
        dl_back: {
          data: null,
          error: null,
          b64: null,
          current: user.value.license_back_photo_file
        },
        dl_selfie: {
          data: null,
          error: null,
          b64: null,
          current: user.value.license_selfie_photo_file
        },
        photo_file: {
          data: null,
          error: null,
          b64: null,
          current: user.value.photo_file
        }
      }
    }

    function initializeLicenseFields() {
      if (user.value.license_front_photo_file !== null) {
        dlFrontLabel.value = 'Click to change license front photo';
        form.value.dl_front.data = {
          name: "Driver's License - Front.jpg",
          size: 0,
          type: 'text/plain'
        }
      }
      if (user.value.license_back_photo_file !== null) {
        dlBackLabel.value = 'Click to change license back photo';
        form.value.dl_back.data = {
          name: "Driver's License - Back.jpg",
          size: 0,
          type: 'text/plain'
        }
      }
      if (user.value.license_selfie_photo_file !== null) {
        dlSelfieLabel.value = 'Click to change license selfie photo';
        form.value.dl_selfie.data = {
          name: "Driver's License - Selfie.jpg",
          size: 0,
          type: 'text/plain'
        }
      }
    }
    async function renderImagePreview(e, target) {
      if (!e.target.files) return;
      const base64Data = await getBase64(e.target.files[0]);

      switch (target) {
        case 'dl_front':
          form.value.dl_front.current = base64Data;
          break;
        case 'dl_back':
          form.value.dl_back.current = base64Data;
          break;
        case 'dl_selfie':
          form.value.dl_selfie.current = base64Data;
          break;
        case 'photo_file':
          form.value.photo_file.current = base64Data;
          break;
      }
    }
  
    function handleUndoChanges() {
      notify('positive', 'The original details and photos of your account has been restored.');
      form.value = getUserData();
      initializeLicenseFields();
      hasChanges.value = false;
    }

    return {
      user,
      form,
      userProperties,
      isLoading,
      save,
      renderAllErrors,
      resetAllErrors,
      dlBackLabel,
      dlFrontLabel,
      dlSelfieLabel,
      renderImagePreview,
      clearInputFile,
      hasChanges,
      handleUndoChanges,
      getUserData,
      initializeLicenseFields,
      showOTPRequest
    };
  }
};
</script>
<style scoped>
.mark-verified {
  color: var(--color-white);
  border-radius: 20px;
  background: #289f28;
}
.dateBirth {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dateBirth label {
  width: 32%;
}
.cover-photo {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.personalInfoSection {
  width: 60%;
}
.profilePictureSection {
  width: 40%;
  text-align: center;
}
</style>
