<template>
  <div class="input-field">
    <q-form autocomplete="off" @submit="updatePassword">
      <div
        class="subtitle1 q-pt-xs q-pb-none"
        v-if="user.has_personalized_password == '1'"
      >
        Old Password
      </div>
      <q-input
        v-if="user.has_personalized_password == '1'"
        v-model="old_password"
        outlined
        type="password"
        placeholder="Enter Password"
        class="cb-input full-width"
        :disable="isLoading"
        :error="errorOldPassword != ''"
        :error-message="errorOldPassword"
        :rules="[
          (val) => (val && val.length > 0) || 'Please enter your old password.',
        ]"
      />

      <div class="subtitle1 q-pt-sm q-pb-none">New Password</div>
      <q-input
        v-model="newPassword"
        outlined
        placeholder="New Password"
        type="password"
        autocomplete="off"
        lazy-rules="true"
        :disable="isLoading"
        class="cb-input full-width"
        :error="errorNewPassword != ''"
        :error-message="errorNewPassword"
        :rules="[
          (val) => (val && val.length > 0) || 'Please enter a new password.',
        ]"
      />

      <div class="subtitle1 q-pt-sm q-pb-none">Repeat Password</div>
      <q-input
        v-model="confirmPassword"
        outlined
        type="password"
        autocomplete="off"
        placeholder="Repeat Password"
        :error="errorConfirmPassword != ''"
        :error-message="errorConfirmPassword"
        class="cb-input full-width"
        :disable="isLoading"
        :rules="[
          (val) =>
            (val && val == newPassword) ||
            'Does not match with new password. Please try again',
        ]"
        required
      />

      <div class="q-pt-xs text-caption">
        <p>
          It is advised to use a password of at least 8 characters.
        </p>
      </div>

      <div class="btn-password text-right">
        <div class="flex items-center gap-10 q-pb-md">
          <q-btn
            class="btn-primary"
            flat
            no-caps
            label="Cancel"
            @click="cancel()"
            icon="arrow_back"
          />
          <q-btn
            class="btn-primary"
            type="submit"
            flat
            no-caps
            :loading="isLoading"
            label="Update Password"
            icon="save"
          />
        </div>
      </div>
    </q-form>
  </div>
</template>
<script setup>
import { notify } from "../../utils/notification";
import { ref } from "vue";
import { useStore } from "vuex";
import { defineEmits } from "vue";
import { useRouter } from "vue-router";

const store = useStore();
const user = store.state.user.data;
const old_password = ref();
const newPassword = ref();
const confirmPassword = ref();
const router = useRouter();
const emit = defineEmits(["cancelPasswordReset"]);

const isLoading = ref(false);
const errorConfirmPassword = ref('');
const errorOldPassword = ref('');
const errorNewPassword = ref('');
function cancel() {
  emit("cancelPasswordReset");
}

function updatePassword() {
  isLoading.value = true;
  store.dispatch("user/updatePassword", {
      old_password: old_password.value,
      password: newPassword.value,
  }).then(() => {
    notify("positive", "You have successfully changed your password.");
    router.push('/dashboard');
  }).catch((err) => {
    if (err.response !== undefined) {
      switch (err.response.status) {
        case 400:
          if (err.response.data.status == 'incorrect_old_password') {
            errorOldPassword.value = 'The old password that you entered is incorrect.';
          } else if (err.response.data.status == 'invalid_password_length') {
            errorNewPassword.value = 'Password must be at least 8 characters in length.';
          }
          break;
      }
    }
  }).finally(() => {
    isLoading.value = false;
  })
}
</script>
<style scoped>
.btn-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
