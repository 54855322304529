import axios from "axios";

const user = {
  state: () => ({
    data: null,
    properties: {
      is_mobile_number_valid: false
    }
  }),

  getters: {
    getFullName: (state) => {
      return state.data.first_name + " " + state.data.last_name;
    },
    getLastName: (state) => {
      return state.data.last_name;
    }
  },

  actions: {
    fetchUserData({ commit }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/users/${payload}`,
          method: "GET",
        }).then((response) => {
          if (response.data.status == "success") {
            commit("setUserData", response.data.data.user);
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    update(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users`,
          method: "PUT",
          data: payload,
        }).then((response) => {
          return resolve(response)
        }).catch((e) => {
          return reject(e)
        })
      });
    },
    requestUpdatePassword({ dispatch }, payload) {
      return new Promise((resolve) => {
        axios({
          url: `/api/v1/users/${payload}/sendpasswordreset`,
          method: "GET"
        }).then((response) => {
          if (response.data.status == "success") {
            dispatch("fetchUserData",response.data.data.id);
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
    updatePassword(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/changepassword`,
          method: "POST",
          data: payload,
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        })
      });
    },
    forgotPasswordRequest(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/forgotpassword`,
          method: "POST",
          data: payload,
        }).then((response) => {
          return resolve(response);
        }).catch((error) => {
          return reject(error);
        })
      });
    },
    forgotPasswordUpdate(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/forgotpassword/confirm`,
          method: "POST",
          data: payload,
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });
    },
    checkApplication({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/${payload}/apply-as-operator`,
          method: "GET",
        }).then((response) => {
          if (response.data.status) {
            if (response.data.status == 'already_an_operator') {

              // If already an operator, check the current user type
              // and morph it to type 2.
              commit('setUserType', 2);

              return resolve({
                status: response.data.status,
                vehicle_application_id: null
              });
            } else if (response.data.status == 'mobile_number_required') {
              return resolve({
                status: response.data.status,
                vehicle_application_id: null
              });
            } else {
              return resolve({
                status: response.data.status,
                vehicle_application_id: response.data.data.vehicle_application_id
              });
            }
          }
        }).catch(() => {
          return reject({
            status: null,
            vehicle_application_id: null
          });
        });
      });
    },
    deleteUser(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/delete`,
          method: "POST",
          data: payload
        }).then((response) => {
          return resolve(response);
        }).catch((error) => {
          return reject(error);
        })
      });
    },
  
    /**
     * Checks the terms and conditions
     * @param { Object } param0 
     */
    checkTerms() {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/terms/check`,
          method: "GET",
        }).then((response) => {
          return resolve(response);
        }).catch((error) => {
          return reject(error);
        });
      });
    },

    updateTerms(_, isConfirmed) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/terms/confirm`,
          method: "PUT",
          data: {
            isConfirmed
          },
        }).then((response) => {
          return resolve(response)
        }).catch((e) => {
          return reject(e)
        })
      });
    },
  },

  mutations: {
    /**
     * Validates the current mobile number that is saved on
     * this user.
     * @param { Object } state 
     */
    validateMobileNumber(state) {
      const regex = /^9\d{9}$/;
      state.properties.is_mobile_number_valid = regex.test(state.data.mobile_number);
    },
    updateCredits(state, credits) {
      state.data.credits = credits;
    },
    setUserData(state, payload) {
      state.data = payload;
    },
    logout(state) {
      state.data = null;
      this.commit('affiliate/clear');
      localStorage.clear();
    },
    setUserType(state, payload) {
      state.data.type = payload;
    },
    setMobileNumberVerifiedDate(state, date) {
      state.data.mobile_number_verified_at = date;
    }
  },
  namespaced: true,
};

export default user;
