<template>
  <router-link :to="`/vehicle/${vehicle.slug}?view=true`" class="mycars-card" v-if="vehicle">
    <q-card class="no-shadow">
      <div class="flex justify-center">
        <div class="photoholder bookingVehicleImg">
          <img style="height: 200px; width: 100%; object-fit: cover;" :src="vehicle.thumbnail"
              @error="handleImgError($event, vehicle.type)"
            />

          <!-- <div class="q-pt-sm q-pl-md absolute-top-right q-px-sm">
          <q-icon name="favorite" color="primary" size="20px" />
        </div> -->
        </div>
        <div class="bookingVehicleDetails">
          <q-card-section class="q-py-none q-px-md text-bold text-accent">
            <div class="car-title ellipsis ">
              {{vehicle.vehicle_name }}
            </div>
          </q-card-section>

          <q-card-section class="q-py-none">
            <div class="q-pt-none text-caption ellipsis text-black">
              <q-icon name="fa-solid fa-location-dot q-pr-sm" color="dark" />
              {{ vehicle.pickup_address }}
            </div>
          </q-card-section>
          <q-card-section class="q-py-none flex items-center gap-20 ">
            <div class="q-pt-none text-caption text-black">
              <q-icon name="fa-solid fa-user q-pr-sm" color="dark" />
              {{ vehicle.number_of_seats }} seater
            </div>
            |

            <div class="q-py-sm text-caption text-black">
              <q-icon name="fa-solid fa-car q-pr-sm" color="dark" />
              {{ vehicle.type }}
            </div>
          </q-card-section>
        </div>
      </div>
    </q-card>
  </router-link>
</template>
<script setup>
import {  computed } from "vue";
import { useStore } from 'vuex'

const store = useStore();
const vehicle = computed(() => store.state.vehicle.vehicleData);
/**
 * Gets called when the vehicle image cannot be loaded for some fucking reason.
 */
 function handleImgError(e, vehicleType)
{
  e.target.src = require(`@/assets/images/${vehicleType}.webp`);
}
</script>
<style scoped>
.mycars-card {
  width: 32%;
  padding: 2px;
  margin-bottom: 8px;
}
.car-title {
  font-size: 16px;
  padding: 10px 0;
}
.text-caption {
  font-size: 12px;
}
.photoholder {
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.photoholder img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.card-operator {
  max-width: 10%;
}
.card-operator-details {
  width: 85%;
}
.bookingVehicleDetails {
  width: 100%;
}
</style>
