<template>
  <q-carousel
    swipeable
    animated
    v-model="slide"
    thumbnails
    infinite
    class="full-width relative"
    v-model:fullscreen="fullscreen"
  >
    <q-carousel-slide
      class="q-pa-none"
      :name="slide"
      v-if="vehicle.gallery.length < 1"
      :img-src="require(`@/assets/images/${vehicle.type}.webp`)"
    >
      <div class="car-header-title absolute-bottom desktop-only">
        <div class="q-pb-md text-h5 text-bold mobileCarTitle">
          {{ vehicle.vehicle_name }}
        </div>
        <div class="car-header-subicons">
          <div class="text-subtitle1 flex items-center">
            <q-icon name="fa-solid fa-user q-pr-sm" />
            {{ vehicle.number_of_seats }} seats
          </div>
          <div class="text-subtitle1 text-capitalize flex items-center">
            <q-icon name="fa-solid fa-car q-pr-sm " />{{ vehicle.type }}
          </div>
          <div class="text-subtitle1 flex items-center">
            <q-icon name="fas fa-cogs m-2 q-pr-sm" />{{ vehicle.transmission }}
          </div>
        </div>
      </div>
    </q-carousel-slide>
    <q-carousel-slide
      v-else
      v-for="(item, index) in vehicle.gallery"
      :key="index"
      class="q-pa-none"
      :name="index"
      :img-src="vehicle.gallery[index]"
    >
      <div class="car-header-title absolute-bottom desktop-only">
        <div class="q-pb-md text-h5 text-bold">
          {{ vehicle.vehicle_name }}
        </div>
        <div class="car-header-subicons">
          <div class="text-subtitle1 flex items-center">
            <q-icon name="fa-solid fa-user q-pr-sm" />
            {{ vehicle.number_of_seats }} seats
          </div>
          <div class="text-subtitle1 text-capitalize flex items-center">
            <q-icon name="fa-solid fa-car q-pr-sm " />{{ vehicle.type }}
          </div>
          <div class="text-subtitle1 flex items-center">
            <q-icon name="fas fa-cogs m-2 q-pr-sm" />
            <span v-if="vehicle.transmission == 'Both'">Automatic & Manual</span>
            <span v-else>{{ vehicle.transmission }}</span>
          </div>
        </div>
      </div>
    </q-carousel-slide>
    <template v-slot:control>
      <q-carousel-control position="top-right" :offset="[18, 18]">
        <q-btn
          push
          round
          dense
          color="white"
          text-color="black"
          :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
          @click="fullscreen = !fullscreen"
        />
      </q-carousel-control>
    </template>
  </q-carousel>
</template>
<script setup>
import { ref,computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const slide = ref(0);
const fullscreen = ref(false);
const vehicle = computed(() => store.state.vehicle.vehicleData)
</script>
<style scoped>
.car-header-title {
  color: var(--color-white);
  background-color: #0000004d;
  padding: 40px;
}
.car-header-subicons {
  display: flex;
  align-items: start;
  gap: 40px;
}
.q-carousel__slide{
  background-size: contain !important;
}
</style>
