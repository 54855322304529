import script from "./Footer.vue?vue&type=script&setup=true&lang=js"
export * from "./Footer.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QIcon});
