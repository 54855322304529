<template>
    <q-card >
      <q-card-section>
        <div style="width: 100%" v-if="!isCreatingBooking">
            <div class="text-h5 text-accent q-py-md">Trip Details</div>
            <p>Please double check your trip details below and click Book Now to proceed.</p>
            <div>
                <q-list bordered separator>
                    <q-item>
                        <q-item-section>Vehicle</q-item-section>
                        <q-item-section side>
                        <q-item-label caption>{{ vehicle }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>Type</q-item-section>
                        <q-item-section side>
                        <q-item-label caption> {{ type }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>Pickup Date</q-item-section>
                        <q-item-section side>
                        <q-item-label caption>{{ pickupDate }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>Return Date</q-item-section>
                        <q-item-section side>
                        <q-item-label caption>{{ returnDate }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>Duration</q-item-section>
                        <q-item-section side>
                        <q-item-label caption>{{ duration }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>Vehicle Rate</q-item-section>
                        <q-item-section side>
                        <q-item-label caption>{{ rate }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>VAT and other charges</q-item-section>
                        <q-item-section side>
                        <q-item-label caption>+ {{ carbnbServiceFee }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>Total Rental Fee</q-item-section>
                        <q-item-section side>
                        <q-item-label caption>{{ total }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item v-if="hasLegitVoucher()">
                        <q-item-section>Voucher Discount</q-item-section>
                        <q-item-section side>
                        <q-item-label caption class="text-negative">- {{ store.state.invoice.voucher.discountValue }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item v-if="hasLegitVoucher()">
                        <q-item-section>Discounted Rental Fee</q-item-section>
                        <q-item-section side>
                        <q-item-label caption class="text-positive"> {{ store.state.invoice.voucher.discountedTotalRentalFee }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
                <div class="text-center q-pa-md">
                    <h5 class="text-positive">{{ reservationFee }}</h5>
                    <p>Reservation Fee to Pay<br>VAT Inclusive</p>
                    <h6>{{ operatorFee }}</h6>
                    <p>Remaining Balance Upon Pick Up</p>
                </div>
                <q-input
                    v-model="destination"
                    outlined
                    placeholder="Enter your destination"
                    :error="isDestinationError"
                    error-message="Please enter your destination."
                    hint="Please declare where you will take this vehicle."
                    class="q-my-md"
                />
                <voucher-input/>
            </div>
        </div>
        <div class="column full-width justify-center items-center content-center q-py-xl" v-else>
            <img src="@/assets/images/loading.gif" style="max-width: 200px; height: auto"/>
            <p class="text-h6 text-bold">Processing Booking..</p>
            <p>Please wait while we process your booking.</p>
        </div>
        <div>
            <div v-if="isLoggedIn">
            <q-banner v-if="user.mobile_number_verified_at == null && user.allow_bookings_on_unverified_numbers == false" inline-actions class="text-white bg-warning">
                Please verify your mobile number to continue.
                <template v-slot:action>
                    <q-btn flat color="white" label="Verify" to="/dashboard" />
                </template>
            </q-banner>
            </div>
            <div v-if="!isCreatingBooking">
                <q-btn
                    label="Book Now"
                    color="primary"
                    class="btn-primary"
                    icon="check_circle_outline"
                    v-if="user.mobile_number_verified_at != null"
                    @click="book()"
                />
                <q-btn
                    label="Cancel"
                    icon="clear"
                    color="secondary"
                    @click="$emit('close', true)"
                    v-close-popup
                    class="btn-primary"
                />
            </div>
        </div>
      </q-card-section>
    </q-card>
</template>
<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import VoucherInput from '@/components/VoucherInput.vue';
import { filterDate } from "@/utils/filterData";
import { notify } from "@/utils/notification";
import { useQuasar } from "quasar";

const store = useStore();
const router = useRouter();

const destination = ref('');
const isCreatingBooking = ref(false);
const isDestinationError = ref(false);
const user = store.state.user.data;
const isLoggedIn = computed(() => store.state.isLoggedIn);
const vehicle = computed(() => store.state.invoice.vehicle);
const type = computed(() => store.state.invoice.type);
const pickupDate = computed(() => store.state.invoice.pickupDate);
const returnDate = computed(() => store.state.invoice.returnDate);
const duration = computed(() => store.state.invoice.duration);
const rate = computed(() => store.state.invoice.rate);
const carbnbServiceFee = computed(() => store.state.invoice.carbnbServiceFee);
const reservationFee = computed(() => getReservationFee());
const operatorFee = computed(() => store.state.invoice.operatorFee);
const total = computed(() => store.state.invoice.total);
const voucher = computed(() => store.state.invoice.voucher);
const $q = useQuasar();

function book() {
    // Do not proceed if the destination is empty
    if (destination.value == '' || destination.value == undefined) {
        isDestinationError.value = true;
        return;
    }

    isCreatingBooking.value = true;
    const query = store.state.vehicle.vehicleSearchQuery;
    const finalPickupDate = query.pickupdate + " " + query.pickupTime;
    const finalReturnDate = query.returndate + " " + query.returnTime;

    const payload = {
        user_id: store.state.user.data.id,
        vehicle_id: store.state.vehicle.vehicleData.id,
        destination: destination.value,
        pickup_date: filterDate(finalPickupDate, "YYYY-MM-DD HH:mm"),
        estimated_return_date: filterDate(finalReturnDate, "YYYY-MM-DD HH:mm"),
        location: vehicle.value.address
    };

    if (voucher.value != null) {
        if (voucher.value.status == 'success') {
            payload.voucher_code = voucher.value.code;
        }
    }

    const referralName = store.state.affiliate.name;

    if (referralName != null) {
        payload.aid = store.state.affiliate.code;
    }

    store.dispatch("booking/createBooking", payload).then((response) => {
        if (response.status == "success") {
            notify(
                "positive",
                "Your booking application has been accepted and ready for reservation fee payment."
            );
            router.push({
                name: "Booking",
                params: { id: response.id },
            });
        }
    }).catch((err) => {
        if (err.response) {
            if (err.response.status === 400) {
                isCreatingBooking.value = false;
                let message = '';
                switch (err.response.data.status) {
                    case 'vehicle_not_available':
                    message = 'It seems that this vehicle is no longer available on your selected dates. Please select another vehicle.';
                    break;
                    case 'vehicle_unavailable':
                    message = 'This vehicle is no longer available for bookings. Please select another vehicle.';
                    break;
                    default:
                    message = err.response.data.data.message;
                }
                $q.dialog({
                    title: 'Oops..',
                    message: message
                }).onOk(() => {
                    router.push('/search');
                }).onDismiss(() => {
                    router.push('/search');
                });
            } else {
                $q.dialog({
                    title: 'Service Unavailable',
                    message: 'We are sorry but it seems like our booking service is not availabe as of this moment. Please try again later.'
                }).onOk(() => {
                    router.push('/search');
                }).onDismiss(() => {
                    router.push('/search');
                });
            }
        }
    }).finally(() => {
        setTimeout(() => {
            isCreatingBooking.value = false;
        }, 1500)
    })
}

/**
 * Gets the reservation fee. It will get the reservation fee from the voucher
 * storage if a valid voucher has been retrieved.
 */
function getReservationFee() {
    if (voucher.value !== null) {
        if (voucher.value.status == 'success') {
            return voucher.value.discountedReservationFee;
        } else {
            return store.state.invoice.reservationFee;
        }
    }

    return store.state.invoice.reservationFee; 
}

/**
 * Determines if the current transaction has legit voucher.
 */
function hasLegitVoucher() {
    if (voucher.value !== null) {
        if (voucher.value.status == 'success') {
            return true;
        }

        return false;
    }

    return false;
}
</script>