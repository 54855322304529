import script from "./HomeNews.vue?vue&type=script&setup=true&lang=js"
export * from "./HomeNews.vue?vue&type=script&setup=true&lang=js"

import "./HomeNews.vue?vue&type=style&index=0&id=65f6c902&lang=css"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn});
