<template>
  <div class="row full-width">
    <div class="col text-center">
      <a href="https://itunes.apple.com/app/id6469505708" target="_blank">
          <q-img style="width: 140px" :src="require('@/assets/images/badges/app_store.png')"/>
      </a>
    </div>
    <div class="col text-center">
      <a href="https://play.google.com/store/apps/details?id=com.cbrentals.carbnb" target="_blank">
        <q-img style="width: 140px" :src="require('@/assets/images/badges/play_store.png')"/>
      </a>
    </div>
  </div>
</template>