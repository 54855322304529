<template>
  <div class="container profileDashboard">
    <ProfileSidebar />
    <section>
      <h5 class="text-accent"><q-icon name="library_books"/> My Bookings</h5>
      <p>This page shows all the bookings with their pick up date and time.</p>
      <div>
        <q-list bordered separator>
          <q-item 
            clickable
            v-ripple v-for="booking in bookings"
            v-bind:key="booking.id"
            :to="'/booking/' + booking.id"
          >
            <q-item-section>
                <div v-if="$q.screen.lt.md" style="display: flex; overflow: hidden; height: 200px; align-items: center; justify-content: center;"
                  class="q-mb-md">
                  <img style="width: 100%; height: 200px; object-fit: cover;" :src="booking.vehicle_photo" @error="handleImgError($event, booking.vehicle_type)"/>
                </div>
              <p>
                <b>{{booking.vehicle_brand }} {{ booking.vehicle_name }}</b>
                <br>
                <q-chip
                  size="12px"
                  style="margin: 0"
                  text-color="white"
                  :color="evaluateChipColor(booking)"
                  dense
                  square
                >
                  {{ booking.status }}
                </q-chip>
                <br>
                <q-icon name="today"/> <small>{{ booking.pickup_date }}</small>
              </p>
            </q-item-section>
            <q-item-section align="right" v-if="$q.screen.gt.md">
                <div>
                  <img style="width: auto; max-height: 100px" :src="booking.vehicle_photo"/>
                </div>
            </q-item-section>
          </q-item>
        </q-list>
        <div v-if="isEmpty" class="q-mt-lg text-center">
          <q-icon name="library_books" size="28px" class="text-accent q-pa-md"/>
          <p>You do not have any bookings yet. To rent your first vehicle, click Book a Vehicle.</p>
          <q-btn color="primary" class="btn-primary" label="Book a Vehicle" to="/search" icon="add"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import ProfileSidebar from "../components/Profile/ProfileSidebar.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

const $q = useQuasar();
const store = useStore();
const isLoading = ref(true);
const isEmpty = ref(false);
const bookings = computed(() => store.state.booking.bookingsByUser);

// Show loading screen.
$q.loading.show({
  message: 'Updating your bookings..'
});

// Retrieve the bookings of this account.
store.dispatch("booking/fetchBookingsFromCustomer", store.state.user.data.id).then((r) => {
  if (r === 'success') {
    isEmpty.value = false;
    isLoading.value = false;
  } else {
    isEmpty.value = true;
    isLoading.value = false;
  }
}).finally(() => {
  $q.loading.hide();
});

/**
 * Evaluates the chip color to display for the status.
 */
function evaluateChipColor(booking)
{
  if (booking.status === 'WAITING FOR PAYMENT') {
    return 'warning';
  } else if (booking.status === 'ACTIVE') {
    return 'positive';
  } else if (booking.status === 'FOR RELEASE') {
    return 'blue';
  } else if (booking.status === 'COMPLETED') {
    return 'black';
  }
}

/**
 * Gets called when the vehicle image cannot be loaded for some fucking reason.
 */
function handleImgError(e, vehicleType)
{
  e.target.src = require(`@/assets/images/${vehicleType}.webp`);
}
</script>
