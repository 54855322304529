/**
 * Validates the current mobile number with the format of
 * 9XXXXXXXXX.
 *
 * @param { String } value 
 */
export function validateMobileNumber(value) {
    if (value == '') {
        return 'Please enter a valid mobile number.';
    }

    const regex = /^9\d{9}$/;

    return regex.test(value) || 'Please enter a valid mobile number.';
}

export function notEmpty()
{
    //
}

/**
 * Determines the size of a base 64 in MB.
 *
 * @param { String } base64 
 */
export function sizeInBase64(base64)
{
    const base64String = base64.split(',')[1];
    const byteCharacters = atob(base64String);
    const byteLength = byteCharacters.length;
    const sizeInMB = byteLength / (1024 * 1024);

    return sizeInMB;
}