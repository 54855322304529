<template>
  <div>
     <q-item clickable v-ripple @click="loadVehicle">
        <q-item-section thumbnail>
            <div class="q-py-xs">
              <img style="height: auto" :src="props.car.image != null ? props.car.image : require(`@/assets/images/${props.car.type}.webp`)"/>
            </div>
        </q-item-section>
        <q-item-section>
          
            <div class="car-title ellipsis q-py-xs">
              <span class="text-bold text-accent">{{ props.car.vehicle_name }}</span><br><span class="text-gray">({{ props.car.plate_number }})</span>
            </div>
            <div class="q-py-xs">
              <q-badge color="blue" v-if="props.car.is_carbnb_managed == 1">
                Parked on Yard
              </q-badge>
              <q-badge v-else color="purple">
                Carbnb Outside Partner
              </q-badge>
            </div>
            <div class="text-caption q-py-xs">
              <div class="flex justify-left">
                <div class="q-mr-md">
                  <q-icon name="fa-solid fa-car q-pr-sm" color="dark" />
                  {{ props.car.type }}
                </div>
                <div>
                  <q-icon name="fa-solid fa-list q-pr-sm" color="dark" />
                  {{ props.car.upcoming_bookings }} Upcoming Bookings
                </div>
              </div>
            </div>
            <div class="text-caption q-py-xs">
              <q-icon
                name="fa-solid fa-circle"
                :color="
                  props.car.status == 'Banned'
                    ? 'red'
                    : props.car.status == 'In-Use'
                    ? 'warning'
                    : props.car.status == 'Available'
                    ? 'positive'
                    : props.car.status == 'Unavailable'
                    ? 'gray' 
                    : props.car.status == 'Reserved'
                    ? 'yellow'
                    : ''
                "
              />
              {{ props.car.status }} 
            </div>
        </q-item-section>
      </q-item>
  </div>
</template>

<script setup>
  import { defineProps, computed } from "vue";
  import { useQuasar } from 'quasar';
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";

  const store = useStore();
  const $q = useQuasar();
  const vehicle = computed(() => store.state.operator.vehicle);
  const router = useRouter();
  const props = defineProps({
    car:Object
  });
  function loadVehicle() {
    if (vehicle.value == undefined || vehicle.value == null) {
      $q.loading.show();
      store.dispatch("operator/getVehicle", props.car.id).finally(() => {
        $q.loading.hide();
        router.push('/my-vehicles/' + props.car.id)
      });
    } else {
      if (vehicle.value.id != props.car.id) {
        $q.loading.show();
        store.dispatch("operator/getVehicle", props.car.id).finally(() => {
          $q.loading.hide();
          router.push('/my-vehicles/' + props.car.id)
        });
      } else {
        router.push('/my-vehicles/' + props.car.id)
      }
    }
  }
</script>