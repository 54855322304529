<template>
    <div class="container profileDashboard">
        <ProfileSidebar />
        <section>
            <h5 class="text-accent"><q-icon name="fas fa-ticket-alt"/> Vouchers</h5>
            <p>To use a voucher, enter the code of the voucher by toggling the <b>I have promo code</b> option during checkout.</p>
            <q-list bordered>
                <q-item @click="openVoucher(voucher)" clickable v-ripple v-for="voucher in vouchers" v-bind:key="voucher.code">
                    <q-item-section>
                        <strong class="text-accent">{{ voucher.title }}</strong>
                        <p>{{ voucher.description }}</p>

                    </q-item-section>
                    <q-item-section thumbnail>
                        <img :src="voucher.image" class="q-mr-sm">
                    </q-item-section>
                </q-item>
            </q-list>
        </section>
    </div>
</template>

<script setup>
import ProfileSidebar from "../components/Profile/ProfileSidebar.vue";
import ModalVoucherAnnouncement from '@/components/ModalVoucherAnnouncement.vue';
import { useStore } from "vuex";
import { computed } from "vue";
import { useQuasar } from 'quasar';

const store = useStore();
const vouchers = computed(() => store.state.voucher.vouchers);
const $q = useQuasar();

function openVoucher(voucher) {
    $q.dialog({
            component: ModalVoucherAnnouncement,
            componentProps: {
            title: voucher.title,
            code: voucher.code,
            description: voucher.description,
            start_date: voucher.start_date,
            end_date: voucher.end_date,
            image: voucher.image
        }
    }).onOk(() => {
        //
    }).onCancel(() => {
        //
    }).onDismiss(() => {
        //
    })
}
</script>