<template>
  <q-layout view="hhh LpR lfr">
    <metainfo>
      <template v-slot:title="{ content }">{{ content }} | Carbnb</template>
    </metainfo>
    <q-header
      v-if="!maintenanceMode"
      elevated
      class="bg-white text-white desktop-hide fixed-top safe-area"
    >
      <q-toolbar>
        <q-btn class="text-primary" dense flat round icon="menu" @click="toggleLeftDrawer" />
        <q-toolbar-title>
          <div class="q-mt-xs">
          <router-link to="/">
            <img
              src="@/assets/images/logo.png"
              class="logo"
          /></router-link>
          </div>
        </q-toolbar-title>
      </q-toolbar>
    </q-header>
    <Drawer
      :show="leftDrawerOpen"
      class="safe-area"
      @controlDrawer="toggleLeftDrawer"
      v-if="!maintenanceMode"
    />
    <q-page-container v-if="!maintenanceMode">
      <Header :isLoggedin="isLoggedin" class="desktop-only" />
      <ModalUpdateRequired      :shown="showUpdateRequiredDialog"/>
      <ModalMobileAdvertisement :shown="showMobileAppAdvertisement" :platform="detectedPlatform" v-on:close-mobile-ads="showMobileAppAdvertisement = false"/>
      <router-view :key="$route.path" />
      <Footer v-if="this.$store.state.displayFooter" />
    </q-page-container>
    <q-dialog
      v-model="showTermsAndConditionsDialog"
      persistent
      :maximized="$q.screen.lt.md"
    >
      <q-card>
        <q-card-section>
          <h5 class="text-accent"><q-icon name="payments"/> Terms and Conditions</h5>
          <div class="row">
            <div class="col">
                <div v-html="termResult?.html"></div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions>
          <q-checkbox 
            v-model="isTicked"
          >
            <div v-html="termResult?.checkboxText"></div>
          </q-checkbox>
            <q-btn
              class="btn-primary"
              label="Proceed"
              color="primary"
              icon="keyboard_arrow_right"
              @click="confirmTerms"
              :disabled="isLoading || !isTicked"
              :loading="isLoading"
            />
            <q-btn
              class="btn-primary"
              label="Go Back"
              color="secondary"
              icon="keyboard_arrow_left"
              @click="showTermsAndConditionsDialog = !showTermsAndConditionsDialog"
              :disabled="isLoading"
            />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showBanDialog" persistent>
      <q-card style="max-width: 320px" class="column justify-center items-center content-center">
        <q-card-section>
          <q-icon class="text-h2 text-primary text-center" name="fas fa-exclamation-triangle" /> 
        </q-card-section>
        <q-card-section class="q-pt-none q-mt-none">
          <div class="text-h5 text-primary text-center q-mb-sm">
            Your account has been banned
          </div>
          <small class="q-pa-sm">Reason: {{ banReason }}</small>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat @click="showBanDialog = false" label="Okay" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showAffiliateInformDialog" position="top">
      <modal-affiliate-inform-user></modal-affiliate-inform-user>
    </q-dialog>
  </q-layout>
</template>

<script>
import { computed, ref } from "vue";
import Header from "@/layout/Header.vue";
import Footer from "@/layout/Footer.vue";
import axios from "axios";
import { notify } from "@/utils/notification";
import Drawer from "./layout/Drawer.vue";
import { useStore } from "vuex";
import { initializeSearchQuery } from "@/utils/initializeSearchQuery";
import ModalUpdateRequired from './components/ModalUpdateRequired.vue';
import ModalMobileAdvertisement from './components/ModalMobileAdvertisement.vue';
import ModalAffiliateInformUser from "./components/ModalAffiliateInformUser.vue";
import { useQuasar } from 'quasar';

export default {
  name: "LayoutDefault",
  components: {
    Header,
    Drawer,
    Footer,
    ModalUpdateRequired,
    ModalMobileAdvertisement,
    ModalAffiliateInformUser
  },

  setup() {
    const leftDrawerOpen = ref(false);
    const store = useStore();
    const maintenanceMode = false;
    const $q = useQuasar();
    let showMobileAppAdvertisement = ref(false);
    const showTermsAndConditionsDialog = ref(false);
    const isTicked = ref(false);
    const termResult = ref({
      status: false,
      html:'',
      checkboxText: ''
    });

    // Detect the current platform so we can show the proper advertisement
    // for our app.
    const detectedPlatform = $q.platform.is.ios ? 'App Store' : 'Google Play';

    // If they are visiting the website but on mobile:
    if (process.env.VUE_APP_BUILD_TARGET == 'website' && $q.platform.is.mobile) {

      // Let's show the modal to them
      showMobileAppAdvertisement.value = true;

      // Save the time it has been shown.
      localStorage.setItem('mobileAppAdvertTime', Date.now());
    }

    return {
      store,
      leftDrawerOpen,
      maintenanceMode: maintenanceMode,
      isLoggedin: ref(false),
      detectedPlatform,
      showMobileAppAdvertisement,
      toggleLeftDrawer() {
        store.commit("setToggleDrawer");
      },
      isTicked,
      showTermsAndConditionsDialog,
      termResult,
    };
  },

  data() {
    return {
      showBanDialog: false,
      showAffiliateInformDialog: false,
      banReason: '',
      showUpdateRequiredDialog: false,
      buildPlatform: process.env.VUE_APP_BUILD_TARGET
    }
  },

  methods: {
    getLocation() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          console.log(position.coords.latitude, position.coords.longitude);
        });
      } else {
        console.log("Please allow to use your location.");
      }
    },
    checkTerms() {
      return new Promise((resolve, reject) => {
        this.store.dispatch('user/checkTerms').then(() => {
          this.termResult = {
            status: true,
            html: null
          };
          return resolve();
        }).catch((error) => {
          var response = error.response;
          var terms = {};
          var status = response.data.status;
          if (status === "user_not_confirmed") {
            terms = response.data.data.terms;
          }
          
          if (this.showBanDialog == true) {
            this.$store.state.justLoggedIn = false;
            return reject();
          }

          this.showTermsAndConditionsDialog = true;
          this.termResult = {
            status:false,
            html: terms.html,
            checkboxText: terms.checkbox_text,
          };
          return reject();
        });
      })
    },
    confirmTerms() {
      this.store.dispatch('user/updateTerms', this.isTicked).then((response) => {
        if (response.data.status === 'success') {
          this. termResult.value = {
            status: true,
            html:'',
            checkboxText:'',
          };
        }
      }).catch((error) => {
        if (error.response.data.status === 'user_already_confirmed_to_the_latest_version') {
          this.termResult.value = {
            status: true,
            html:'',
            checkboxText:'',
          };
        }
      });
      this.showTermsAndConditionsDialog = false;
    }
  },

  // Initialize a search query recorrection every app creation.
  beforeCreate() {
    initializeSearchQuery(); 
  },

  /**
   * Main entry point of the application.
   */
  created() {
    // Clean up the required version keys and links on app create.
    localStorage.removeItem('required_version');
    localStorage.removeItem('app_store');
    localStorage.removeItem('play_store');

    let system = this;
    axios.interceptors.request.use(
      function (config) {
        const token = localStorage.getItem("user_loggedin_token");
        const user_id = localStorage.getItem("user_loggedin_id");
        const Auth = `Bearer ${user_id} ${token}`;
        config.headers.Authorization = Auth;

        // Include the platform on each request.
        config.headers['X-AppPlatform'] = process.env.VUE_APP_BUILD_TARGET;

        // If the app is using an API tunnelled from ngrok.
        config.headers['ngrok-skip-browser-warning'] = true;

        // Include the version on each request.
        if (config.headers['X-AppPlatform'] === 'mobile') {
          config.headers['X-AppVer'] = btoa(process.env.VUE_APP_VERSION);
        }

        return config;
      },
      function (error) {
        console.error(
          "The Axios HTTP request has failed. Please see the logs for more details."
        );
        console.error(error);
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status == 400) {
          if (error.response.data.status == 'update_required') {
            console.error('You are using an outdated version of the app. The system requires you to update to use its services.');
            system.$store.commit("logout");
            system.$store.commit("user/logout");
            localStorage.setItem("required_version", error.response.data.data.required_version);
            localStorage.setItem("app_store", error.response.data.data.app_store);
            localStorage.setItem("play_store", error.response.data.data.play_store);
            system.showUpdateRequiredDialog = true;
          }
        }
        if (error.response.status == 403) {
          system.$store.commit("logout");
          system.$store.commit("user/logout");
          system.$router.push("/login").then(() => {
            if (error.response?.data.status === 'banned_account') {
              localStorage.removeItem("user_loggedin_token");
              localStorage.removeItem("verification_user_id");
              localStorage.removeItem("verification_user_email");
              system.banReason = error.response.data.data.reason;
              system.showBanDialog = true;
              system.showTermsAndConditionsDialog = false;

              return;
            } else {
              notify("warning", "Please sign in your account again to access this page.");
            }
          });
        }

        if (
          error.response.status == 500
        ) {
          if (
            error.response.data.data.message != "not found" &&
            error.response.data.data.message != "vehicle not found"
          ) {
            notify("red", error.response.data.data.message, "", 6000);
            return { status: error.response.data.status };
          }
        }
        return Promise.reject(error);
      }
    );
  },
  watch: {
    $route () {
      if (this.store.state.affiliate.informed == false
        && this.store.state.affiliate.can_use == true
        && this.store.state.affiliate.discount !== null)
      {
        this.showAffiliateInformDialog = true;
      }
      const isLoggedIn = computed(() => this.store.state.isLoggedIn)
      if (isLoggedIn.value && this.$store.state.justLoggedIn === true) {
        this.checkTerms();
        this.$store.state.justLoggedIn = false;
      }
    }
  },
};
</script>
<style scoped>
.logo {
  cursor: pointer;
  height: 35px;
  object-fit: contain;
}
</style>
