<template>
  <section class="container-fluid bg-dark q-pa-md">
    <div class="container">
      <div class="row q-my-lg text-center">
        <div class="col-12 col-md-3">
          <q-img :src="require('@/assets/images/logo-w.png')" style="max-width: 200px"/>
        </div>
        <div class="col-12 col-md-3">
          <h5 class="text-white"><q-icon name="phone" /> Contact Us</h5>
          <div><q-icon name="email" /> <a href="mailto:support@carbnbrentals.com" class="text-white text-caption">support@carbnbrentals.com</a></div>
          <div><q-icon name="phone" /> <a href="tel:+0285361446" class="text-white text-caption">02 (8288-7096)</a></div>
          <div><q-icon name="phone_iphone" /> <a href="tel:+639276400108" class="text-white text-caption">(+63) 917 527 0108</a></div>
        </div>
        <div class="col-12 col-md-3">
          <h5 class="text-white"><q-icon name="apartment" /> Our Main Office</h5>
          <span class="text-white text-caption"> 11F Insular Life Building 6781<br>Ayala Avenue 1209<br>Makati City</span>
        </div>
        <div class="col-12 col-md-3">
          <h5 class="text-white"><q-icon name="smartphone" /> Get it on App Store and Play Store!</h5>
          <AppStorePlayStoreBadges v-if="buildPlatform == 'website'"/>
        </div>
      </div>
      <div class="row q-my-lg text-center">
        <div class="col-12 col-md-3">
          <h6 class="text-white"><q-icon name="garage" /> Taguig City Hub</h6>
          <span class="text-white text-caption">Lot 111, E. Aguinaldo Ave., AFPOVAI<br>Taguig City<br>Metro Manila</span>
        </div>
        <div class="col-12 col-md-3">
          <h6 class="text-white"><q-icon name="garage" /> Quezon City Hub</h6>
          <span class="text-white text-caption">52 Samonte, Novaliches<br>Quezon City<br>Metro Manila</span>
        </div>
        <div class="col-12 col-md-3">
          <h6 class="text-white"><q-icon name="garage" /> San Juan City Hub</h6>
          <span class="text-white text-caption"> 14 G Ocampo<br> San Juan City <br>Metro Manila</span>
        </div>
        <div class="col-12 col-md-3">
          <h6 class="text-white"><q-icon name="garage" /> Tarlac City Hub</h6>
          <span class="text-white text-caption"> Block 3 Highway<br>Brgy. Balingcanaway<br>Tarlac City<br>Central Luzon</span>
        </div>
      </div>
      <div class="row q-my-lg text-center">
        <div class="col-12 col-md-6">
          <h6 class="text-white"><q-icon name="garage" /> Cebu City</h6>
          <span class="text-white text-caption"> 16 Floor, KMC Skyrise 4B<br> Geonzon St., Cebu IT Park<br> Cebu City, Philippines</span>
        </div>
        <div class="col-12 col-md-6">
          <h6 class="text-white"><q-icon name="garage" /> Las Piñas City Hub</h6>
          <span class="text-white text-caption"> 16 Benjamin Street<br>Las Piñas, 1747<br>Metro Manila</span>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-12">
          <h5 class="text-white"><q-icon name="thumb_up" /> Like and Follow us!</h5>
        </div>
        <div class="col-12 flex-wrap flex-center">
            <a href="https://web.facebook.com/Carbnbbooking/" class="q-mx-md" target="_blank"><q-img :src="require('@/assets/images/fb-logo.png')" style="max-width: 50px"/></a>
            <a href="https://www.instagram.com/carbnbph/" class="q-mx-md" target="_blank"><q-img :src="require('@/assets/images/ig-logo.png')" style="max-width: 50px"/></a>
            <a href="https://www.tiktok.com/@carbnbrentals" class="q-mx-md" target="_blank"><q-img :src="require('@/assets/images/tiktok-logo.png')" style="max-width: 50px"/></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import AppStorePlayStoreBadges from '@/components/AppStorePlayStoreBadges'

const buildPlatform = process.env.VUE_APP_BUILD_TARGET;
</script>

<style scoped>

</style>
