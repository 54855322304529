<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <q-img :src="props.image" :ratio="16/9"/>
        <h5 class="text-accent">{{ props.title }}</h5>
        <p><q-icon name="calendar_today" class="text-primary"/> {{ props.start_date }} - <q-icon name="calendar_today" class="text-primary"/> {{ props.end_date }}</p>
        <p>{{ props.description }}</p>
        <center>
            <h5 class="text-grey-7">{{ props.code }}</h5>
            <p>Use the promo code above upon check out.</p>
        </center>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" icon-right="thumb_up" label="Nice" @click="onOKClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useDialogPluginComponent } from 'quasar'
import { defineProps } from 'vue';

const { dialogRef, onDialogOK } = useDialogPluginComponent()
const props = defineProps([
    'title',
    'code',
    'description',
    'start_date',
    'end_date',
    'image'
]);
function onOKClick () {
    onDialogOK()
}
</script>