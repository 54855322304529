<template>
  <div>
    <q-card style="min-width: 350px">
        <q-card-section>
        <h5 class="text-accent"><q-icon name="discount"></q-icon> Beep, beep!</h5>
          For using the link of <b>{{ name }}</b>. You are eligible to claim <strong>{{ labelDiscountValue }} </strong> off on your next booking!<br><br>
          Simply sign in your account or create an account then book a vehicle to claim the discount!<br><br>The promo code will be automatically added upon checkout.
        </q-card-section>
    </q-card>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";

const store = useStore();

const name = ref(null);
const discountValue = ref(null);
const discountType = ref(null);

const labelDiscountValue = ref(null);
if (store.state.affiliate.name != null) {
    name.value = store.state.affiliate.name;
}

if (store.state.affiliate.discount) {
    discountValue.value = store.state.affiliate.discount.value;
    discountType.value = store.state.affiliate.discount.type;

    // Magic number to define that the discount type is percentage.
    // too lazy to add some fucking enum as of this point. sori
    if (discountType.value == 2) {
        labelDiscountValue.value = discountValue.value + '%';
    } else {
        // Monetary fucking discount
        labelDiscountValue.value = 'PHP ' + discountValue.value;
    }
}

onMounted(() => {
    store.commit('affiliate/setInformed', true);
});
</script>

<style>

</style>