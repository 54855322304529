<template>
  <section>
    <div class="row justify-center items-center">
        <div>
            <div class="text-center">
                <q-icon name="check" color="red" size="3rem" />
                <div class="text-h6 text-bold q-pa-md">Your {{ term }} is now confirmed</div>
                <div class="w-100 text-caption">The payment amounting to PHP {{ invoice.reservation_fee }} was successfully made.</div>
                <br>
            </div>
            <q-img src="/graphics/line.png" style="height: 8px;"/>
            <br>
            <div class="row q-ml-md q-mt-lg">
                <div class="col-4 text-bold q-py-xs q-px-xs">Booking Code</div>
                <div class="col-8 q-py-xs q-px-xs">{{ invoice.booking_code }}</div>
                <div class="col-4 text-bold q-py-xs q-px-xs">Pick Up Date</div>
                <div class="col-8 q-py-xs q-px-xs">{{ invoice.pickup_date }}</div>
                <div class="col-4 text-bold q-py-xs q-px-xs">Return Date</div>
                <div class="col-8 q-py-xs q-px-xs">{{ invoice.estimated_return_date }}</div>
                <div class="col-4 text-bold q-py-xs q-px-xs" v-if="invoice.type != invoiceTypes.booking">New Return Date</div>
                <div class="col-8 q-py-xs q-px-xs" v-if="invoice.type != invoiceTypes.booking">{{ invoice.extension.newReturnDate }}</div>
                <div class="col-4 text-bold q-py-xs q-px-xs" v-if="invoice.type != invoiceTypes.booking">Additional Hours</div>
                <div class="col-8 q-py-xs q-px-xs" v-if="invoice.type != invoiceTypes.booking">+ {{ invoice.extension.hours }} hour(s)</div>
            </div>
            <br>
            <q-img src="/graphics/line.png" style="height: 8px;"/>
            <br>
            <div class="row q-ml-md q-mt-lg">
                <div class="col-4 text-bold q-py-xs q-px-xs">Vehicle</div>
                <div class="col-8 q-py-xs q-px-xs">{{ invoice.vehicle_brand }} {{ invoice.vehicle_name }} {{ invoice.vehicle_year }}</div>
                <div class="col-4 text-bold q-py-xs q-px-xs">Transmission</div>
                <div class="col-8 q-py-xs q-px-xs">{{ invoice.transmission }}</div>
                <div class="col-4 text-bold q-py-xs q-px-xs">Type</div>
                <div class="col-8 q-py-xs q-px-xs">{{ invoice.vehicle_type }}</div>
            </div>
            <q-img src="/graphics/line.png" style="height: 8px;" v-if="invoice.type == invoiceTypes.booking"/>
            <div class="row q-ml-md q-mt-lg" v-if="invoice.type == invoiceTypes.booking">
                <div class="col-5 text-bold q-py-xs q-px-xs">Total Rental Fee</div>
                <div class="col-7 q-py-xs q-px-xs">PHP {{ invoice.total_rental_fee }}</div>
            </div>
            <q-img src="/graphics/line.png" style="height: 8px;" v-if="invoice.type == invoiceTypes.booking"/>
            <div class="row q-ml-md q-mt-lg" v-if="invoice.type == invoiceTypes.booking">
                <div class="col-5 text-bold q-py-xs q-px-xs">Reservation Fee (Paid)</div>
                <div class="col-7 q-py-xs q-px-xs">PHP {{ invoice.reservation_fee - invoice.vat }}</div>
                <div class="col-5 text-bold q-py-xs q-px-xs">+ VAT Charge (Paid)</div>
                <div class="col-7 q-py-xs q-px-xs">PHP {{ invoice.vat }}</div>
                <div class="col-5 text-bold q-py-xs q-px-xs">Reservation Fee with VAT (Paid)</div>
                <div class="col-7 q-py-xs q-px-xs">PHP {{ invoice.reservation_fee }}</div>
                <div class="col-5 text-bold q-py-xs q-px-xs">Remaining Balance</div>
                <div class="col-7 q-py-xs q-px-xs">PHP {{ invoice.operator_fee }}</div>
            </div>
            <div class="text-center q-py-lg">
                <q-btn
                    class="btn-primary"
                    label="Confirm"
                    icon-right="keyboard_arrow_right"
                    @click="closePaymentApp"
                />
            </div>
        </div>
    </div>
  </section>
</template>

<script setup>
    import { computed } from "vue";
    import { useStore } from "vuex";
    import { useRouter, useRoute } from "vue-router";
    import invoiceTypes from '@/utils/payment/invoiceTypes';

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const invoice = computed(() => { return store.state.payment.invoice });
    const term = computed(() => {
        if (invoice.value.type != invoiceTypes.booking) {
            return 'extension';
        } else {
            return 'booking';
        }
    });
    /**
    * Simply returns the user to their booking view.
    */
    function closePaymentApp() {
        router.push({
            name: 'Booking',
            params: {
                booking_id: route.params.id
            }
        });
    }
</script>