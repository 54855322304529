const invoice = {
    state: () => ({
        vehicle: '',
        type: '',
        pickupDate: '',
        returnDate: '',
        duration: '',
        rate: '',
        carbnbServiceFee: '',
        carwashFee: '',
        voucher: null,
        reservationFee: '',
        operatorFee: '',
        total: ''
    }),
    mutations: {
      save(state, invoice) {
        state.vehicle = invoice.vehicle;
        state.type = invoice.type;
        state.pickupDate = invoice.pickup_date;
        state.returnDate = invoice.return_date;
        state.duration = invoice.duration;
        state.rate = invoice.rate;
        state.carbnbServiceFee = invoice.carbnbServiceFee;
        state.carwashFee = invoice.carwash_fee;
        state.reservationFee = invoice.reservation_fee;
        state.operatorFee = invoice.operator_fee;
        state.total = invoice.total;
        if (invoice.voucher != null) {
          state.voucher = invoice.voucher;
        } else {
          state.voucher = null;
          console.log('The voucher in-memory has been cleared.');
        }
      },
      clearVoucher(state) {
        state.voucher = null;
      }
    },
    actions: {
        //
    },
    namespaced: true,
};
  
export default invoice;