<template>
  <div>
    <q-input
      v-if="addPromoCode && !isVoucherLegit"
      v-model="code"
      outlined
      placeholder="Promo Code"
      :error="codeErrorMessage != null"
      :error-message="codeErrorMessage"
      hint="Enter your promo code."
      :disable="isRedeeming"
    >
      <template v-slot:after>
        <q-btn
          icon-right="redeem"
          color="positive"
          label="Redeem"
          @click="redeem"
          :loading="isRedeeming"
          v-if="!isVoucherLegit"
        />
      </template>
    </q-input>
    <div class="q-py-md" v-if="showReferralVoucherNotifcation">
      <q-banner rounded class="bg-positive text-white">
      We already added the voucher code that came from the referral link.
        <template v-slot:action>
          <q-btn flat color="white" label="Nice!" @click="showReferralVoucherNotifcation = !showReferralVoucherNotifcation" />
        </template>
      </q-banner>
    </div>
    <q-toggle
      v-model="addPromoCode"
      label="I have a promo code."
      @update:model-value="handleToggleChange"
      :disable="isRedeeming || isVoucherLegit"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const addPromoCode = ref(false);
const code = ref();
const codeErrorMessage = ref(null);
const isRedeeming = ref(false);
const isVoucherLegit = ref(false);
const store = useStore();
const showReferralVoucherNotifcation = ref(false);

if (store.state.affiliate.code != null) {
  addPromoCode.value = true;
  code.value = store.state.affiliate.code;
  redeem();
}

function handleToggleChange() {
  code.value = '';
  if (store.state.affiliate.code != null) {
    code.value = store.state.affiliate.code;
  }
  isRedeeming.value = false;
  isVoucherLegit.value = false;
  codeErrorMessage.value = null;
  store.commit('vehicle/setVoucherCode', null);
}

function redeem() {
  if (code.value == '') {
    codeErrorMessage.value = 'Please enter a voucher code.';
    return;
  }

  isRedeeming.value = true;
  store.commit('vehicle/setVoucherCode', code.value);
  store.dispatch('vehicle/checkAvailability', store.state.vehicle.vehicleData.id).then((res) => {
    if (res.status == 'available') {
      if (store.state.invoice.voucher !== null) {
        if (store.state.invoice.voucher.status == 'error') {
          codeErrorMessage.value = store.state.invoice.voucher.message;
        } else {
          codeErrorMessage.value = null;
          if (code.value == store.state.affiliate.code) {
            showReferralVoucherNotifcation.value = true;
          }
        }
      }
    }
  }).catch(() => {
    //
  }).finally(() => {
    isRedeeming.value = false;
  })
}
</script>