<template>
    <div class="container ip-cont" v-if="!isLoading">
      <h4 class="full-width q-mb-md text-accent q-mt-lg">Apply as Operator</h4>

      <!-- The view if the user is logged in and is not yet an operator. -->
      <div v-if="isLoggedin && user.type != 2">
        <VehicleForm
          :forApplication="true"
          v-if="isLoggedin && user.type != 2 && applicationStatus == 'canApply'"
        />
        <!-- If their application is in queue -->
        <div class="row" v-if="isLoggedin && user.type != 2 && applicationStatus == 'forReview'">
          <div class="col-12 col-md-6 q-pr-md">
            <h5 class="text-grey-7">Your application is pending</h5>
            <p>Your application is currently pending for review. Please constantly check this page for more updates.</p>
            <br>
            <p>Please be advised that we may receive a huge amount of applications but our onboarding officers will soon be in touch with you.</p>
            <br>
            <p>Should you want to cancel your application you may do so by clicking the Withdraw Application below.</p>
            <div class="q-py-lg text-center">
                <q-btn
                  color="primary"
                  icon="cancel"
                  size="md"
                  label="Withdraw Application"
                  style="width: 250px"
                  :loading="isCancelling"
                  @click="showWithdrawConfirmation"
                />
            </div>
            <b>Thank you!</b><br><br>
            <b class="text-accent">Carbnb Operator Onboarding Team</b>
          </div>
          <div class="col-12 col-md-6 q-pr-md">
            <q-img
              :src="require('@/assets/images/apply-as-operator/carbnb-operator-requirements.jpg')"
              alt="Carbnb Herlene Budol as ambasaddor"
              :ratio="1"
              class="q-mt-lg"
            />
          </div>
        </div>
        <!-- If their application is now on officer's review -->
        <div class="row" v-if="isLoggedin && user.type != 2 && applicationStatus == 'onReview'">
          <div class="col-12 col-md-6 q-pr-md">
            <h5 class="text-green-7"><q-icon name="check"/> Your application is being reviewed</h5>
            <p>Your application is currently being reviewed by one of our Onboarding Officers. Please keep your lines open as they might call soon.</p>
            <br>
            <p>Please be advised that we may receive a huge amount of applications but our onboarding officers will soon be in touch with you.</p>
            <br>
            <b>Thank you!</b><br><br>
            <b class="text-accent">Carbnb Operator Onboarding Team</b>
          </div>
          <div class="col-12 col-md-6 q-pr-md">
            <q-img
              :src="require('@/assets/images/apply-as-operator/carbnb-aap.jpg')"
              alt="Carbnb Herlene Budol as ambasaddor"
              :ratio="1"
              class="q-mt-lg"
            />
          </div>
        </div>
      </div>

      <!-- The view if the user is logged in and is an operator. -->
      <div v-if="isLoggedin && applicationStatus == 'alreadyOperator'">
        <div class="row">
          <div class="col-12 col-md-6 q-pr-md">
            <h5 class="text-grey-7">You are already an operator</h5>
            <p>You can go to your Dashboard and go to My Vehicles to manage your vehicles.</p>
            <div class="q-py-lg text-center">
                <q-btn
                  color="primary"
                  icon-right="arrow_right"
                  size="md"
                  label="My Vehicles"
                  style="width: 250px"
                  to="/my-vehicles"
                />
            </div>
            <p>If you have any operator related questions, please free to contact us anytime 24/7!</p>
            <div class="text-left">
              <span class="text-weight-bold text-subtitle1"
                >Mr. Rodel Alambatin</span
              ><br />
              <q-icon name="phone" class="q-mr-sm"></q-icon
              ><a href="tel:+639159979134">+639159979134</a><br />
              <q-icon name="phone" class="q-mr-sm"></q-icon
              ><a href="tel:+639305274327">+639305274327</a><br />
              <q-icon name="email" class="q-mr-sm"></q-icon
              ><a href="mailto:info@carbnbrentals.com"
                >info@carbnbrentals.com</a
              >
              <br>
              <br>
              <span class="text-weight-bold text-subtitle1"
                ><q-icon name="location_on" /> Main Office</span
              ><br />
              11F Insular Life Building, 6781 Ayala Avenue 1209, Makati City
              <br />
              <br />
              <span class="text-weight-bold text-subtitle1"
                ><q-icon name="location_on" /> Hubs</span
              ><br />
              <ul>
                <li>Lot 111, E. Aguinaldo Ave., AFPOVAI, Taguig City, Metro Manila</li>
                <li>52 Samonte, Novaliches, Quezon City, Metro Manila</li>
                <li>14 G Ocampo, San Juan City, Metro Manila</li>
              </ul>
            </div>
            <br>
            <b>Thank you!</b><br><br>
            <b class="text-accent">Carbnb Operator Onboarding Team</b>
          </div>
          
          <div class="col-12 col-md-6 q-pr-md">
            <q-img
              :src="require('@/assets/images/apply-as-operator/carbnb-aap.jpg')"
              alt="Carbnb Herlene Budol as ambasaddor"
              :ratio="1"
              class="q-mt-lg"
            />
          </div>
        </div>
      </div>

      <!-- The view if the user is not logged in. -->
      <div class="row" v-if="!isLoggedin">
        <div class="col-12 col-md-6">
          <div class="q-my-lg">
            <div class="txt">
              <p>For interested Operators, please <b>Sign In</b> or <b>Create an Account</b> to continue.</p>
            </div>
            <div class="text-center">
              <div class="q-py-lg">
                <q-btn
                  color="primary"
                  size="md"
                  label="Sign In"
                  style="width: 250px"
                  to="/login"
                />
              </div>
              <div>or</div>
              <div class="q-py-lg">
                <q-btn
                  color="primary"
                  size="md"
                  label="Create a Carbnb Account"
                  style="width: 250px"
                  to="/signup"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <q-img
            :src="require('@/assets/images/apply-as-operator/carbnb-aap.jpg')"
            alt="Carbnb Herlene Budol as ambasaddor"
            :ratio="1"
          />
        </div>
    </div>
    </div>
</template>

<script setup>
  import { computed, ref } from "vue";
  import { useQuasar } from 'quasar'
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { notify } from "../utils/notification";
  import VehicleForm from "../components/Car/VehicleForm.vue";

  const $q = useQuasar();
  const router = useRouter();
  const store = useStore();
  const isLoading = ref(true);
  const isCancelling = ref(false);
  const user = computed(() => store.state.user.data);
  const isLoggedin = computed(() => store.state.isLoggedIn);
  const applicationStatus = ref();
  const currentVehicleApplicationId = ref()


  $q.loading.show({opacity: 1});
  isLoading.value = true;
  if (user.value && isLoggedin.value) {
    store.dispatch("user/checkApplication", user.value.id).then((r) => {
      switch (r.status) {
        case 'can_submit_application':
          applicationStatus.value = "canApply";
          break;
        case 'application_reviewing':
          applicationStatus.value = "onReview";
          currentVehicleApplicationId.value = r.vehicle_application_id;
          break;
        case 'application_pending':
          applicationStatus.value = "forReview";
          currentVehicleApplicationId.value = r.vehicle_application_id;
          break;
        case 'mobile_number_required':
          notify(
            "orange",
            "We need your mobile number before applying as operator. Please update your account's mobile number under Account Verification."
          );
          router.push("/dashboard");
          break;
        case 'already_an_operator':
          applicationStatus.value = "alreadyOperator";
          currentVehicleApplicationId.value = null;
          break;
      }
    }).catch(() => {
      $q.loading.hide();
      isLoading.value = false;
    }).finally(() => {
      $q.loading.hide();
      isLoading.value = false;
    });
  } else {
    $q.loading.hide();
    isLoading.value = false;
  }

  function showWithdrawConfirmation() {
    $q.dialog({
      title: 'Withdraw Application?',
      message: 'You can only withdraw your application if your application is still pending but you can always resubmit again. Continue withdrawal?',
      cancel: true,
      persistent: true
    }).onOk(() => {
        isCancelling.value = true;
        store.dispatch("vehicle/withdrawApplication", currentVehicleApplicationId.value).then(() => {
          notify(
            "positive",
            "You have successfully withdrawn your application."
          );
          applicationStatus.value = "canApply";
        }).catch(() => {
          notify(
            "negative",
            "There was an error while trying to cancel your application. Please try again later."
          );
        }).finally(() => {
          isCancelling.value = false;
        });
    }).onOk(() => {
        //
    }).onCancel(() => {
        //
    }).onDismiss(() => {
        //
    })
  }

</script>

<style>

</style>