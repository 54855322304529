<template>
  <q-drawer v-model="leftDrawerOpen" :width="350" :breakpoint="400"
    overlay no-swipe-open no-swipe-close no-swipe-backdrop>

    <q-list padding  style="height: calc(100% - 200px); margin-top: 200px; border-right: 1px solid #ddd">
      <div class="q-pa-md">
        <q-btn 
          dense
          round
          icon="arrow_back"
          size="16px"
          class="bg-accent text-white"
          @click="closeDrawer"
        />
      </div>
      <q-item clickable v-ripple @click="closeDrawer" to="/search">
        <q-item-section avatar>
          <q-icon name="search" />
        </q-item-section>

        <q-item-section>
          <span v-if="user">Book Now</span>
          <span v-else>Browse Vehicles</span>
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="closeDrawer" to="/bookings" v-if="user">
        <q-item-section avatar>
          <q-icon name="library_books" />
        </q-item-section>

        <q-item-section>
          My Bookings
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="closeDrawer" to="/dashboard" v-if="user">
        <q-item-section avatar>
          <q-icon name="person" />
        </q-item-section>

        <q-item-section>
          My Account
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="closeDrawer" to="/vouchers" v-if="user">
        <q-item-section avatar>
          <q-icon name="fas fa-ticket-alt" />
        </q-item-section>

        <q-item-section>
          Vouchers
        </q-item-section>
      </q-item>
      <!-- <q-item clickable v-ripple @click="closeDrawer" to="/wallet" v-if="user">
        <q-item-section avatar>
          <q-icon name="payments" />
        </q-item-section>

        <q-item-section>
          My Wallet
        </q-item-section>
      </q-item> -->
      <q-item clickable v-ripple @click="closeDrawer" to="/apply-as-operator" v-if="!user || user?.type != 2">
        <q-item-section avatar>
          <q-icon name="edit_note" />
        </q-item-section>

        <q-item-section>
          Apply as Operator
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="closeDrawer" to="/my-vehicles" v-if="user && user.type == 2">
        <q-item-section avatar>
          <q-icon name="directions_car" />
        </q-item-section>

        <q-item-section>
          My Vehicles
        </q-item-section>
      </q-item>
      <q-item clickable v-ripple @click="closeDrawer" to="/settings" v-if="user">
        <q-item-section avatar>
          <q-icon name="settings" />
        </q-item-section>

        <q-item-section>
          Settings
        </q-item-section>
      </q-item>
      <q-item v-ripple clickable to="/about-us" @click="closeDrawer">
        <q-item-section avatar>
          <q-icon name="info" />
        </q-item-section>

        <q-item-section>
          About Us
        </q-item-section>
      </q-item>
    </q-list>
    <q-img class="absolute-top" :src="require('@/assets/images/drawer.jpg')" style="height: 200px">
      <div class="absolute-bottom bg-transparent" v-if="user">
        <q-avatar size="56px" class="q-mb-sm">
            <img
              :src="
                user.photo_file != null
                  ? user.photo_file
                  : require('@/assets/images/default_profile.jpg')
              "
              style="object-fit: cover"
            />
        </q-avatar>
        <div class="text-weight-bold">
          {{ user.first_name ?? "Unknown User" }}
        </div>
        <div>{{user.email ?? "Unknown Email"}}</div>
        <div class="q-mt-md">
          <q-btn color="primary" icon="logout" @click="logout(), closeDrawer()" label="Sign Out" size="md" />
        </div>
      </div>
      <div class="absolute-bottom bg-transparent" v-else>
          <p>You must sign in or create an account to rent a vehicle.</p>
          <q-btn class="full-width" color="primary" icon="add" @click="closeDrawer()" to="/signup" label="Create an Account" size="md" />
          <q-btn class="q-mt-sm full-width" color="primary" icon="login" @click="closeDrawer()" to="/login" label="Sign In" size="md" />
      </div>
    </q-img>
  </q-drawer>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const leftDrawerOpen = computed(() => store.state.leftDrawerOpen);
const user           = computed(() => store.state.user.data);
const router = useRouter();

function logout() {
  store.dispatch("logout").then((response) => {
    store.commit("user/logout");
    store.commit("booking/logout");
    if (response == "logged_out") {
      router.push("/login");
    }
  });
}

function closeDrawer() {
  store.commit("setToggleDrawer")
}
</script>

<style scoped>
.q-icon {
  color: #343a40;
}
</style>
