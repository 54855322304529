import script from "./CarSideBar.vue?vue&type=script&setup=true&lang=js"
export * from "./CarSideBar.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QCard,QCardSection,QInput,QPopupProxy,QDate,QBtn,QTime,QSelect,QBanner,QSkeleton,QDialog});qInstall(script, 'directives', {ClosePopup});
