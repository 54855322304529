import script from "./Vouchers.vue?vue&type=script&setup=true&lang=js"
export * from "./Vouchers.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QList,QItem,QItemSection});qInstall(script, 'directives', {Ripple});
