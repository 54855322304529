<template>
  <div class="container ip-cont">
    <q-form
      class="cb-form"
      @submit="createAccount" 
      autofocus
    >
      <h4 class="text-accent">Create Account</h4>
      <p class="q-py-xs text-center">To rent a vehicle, please create an account.
      </p>

      <div class="q-px-xl q-pb-xl q-mt-md mobileLessPadding">
        <q-input
          v-model="first_name"
          outlined
          class="cb-input q-py-md"
          placeholder="First Name"
          :rules="[(val) => (val && val.length > 0) || 'Please enter your first name.']"
        />
        <q-input
          v-model="last_name"
          outlined
          class="cb-input q-py-md"
          placeholder="Last Name"
          :rules="[(val) => (val && val.length > 0) || 'Please enter your last name.']"
        />
        <q-input
          v-model="email"
          outlined
          type="email"
          class="cb-input q-py-md"
          placeholder="Email"
          :rules="[(val) => (val && val.length > 0) || 'Email address is required.']"
        />
        <q-input
          v-model="mobile_number"
          outlined
          prefix="+63"
          maxlength="10"
          class="cb-input q-py-md"
          placeholder="9XXXXXXXXX"
          :rules="[validateMobileNumber]"
        />
        <div class="flex items-center q-my-md">
          <q-checkbox v-model="agreePolicy" label="I have read and agree to the Privacy Policy"
          ></q-checkbox>
        </div>

        <q-btn
          class="btn-primary text-white"
          :loading="isLoading"
          icon="add_circle_outline"
          label="Create Account"
          type="submit"
        />
      </div>
      <div class="q-pt-sm text-center text-subtitle2 text-weight-regular">
        <router-link to="/forgot-password"
          ><span class="text-primary text-weight-regular"
            >I forgot my password</span
          >
        </router-link>
      </div>
      <div class="q-pt-sm text-center text-subtitle2 text-weight-regular">
        Already have an Account?
        <router-link to="/login"><span class="text-primary text-weight-regular">Click here</span></router-link>
      </div>
    </q-form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { notify } from "../utils/notification";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { validateMobileNumber } from '@/validations/index.js'

const store = useStore();
const router = useRouter();
const first_name = ref();
const last_name = ref();
const email = ref();
const mobile_number = ref('');
const agreePolicy = ref(false);
const isLoading = ref(false);

function createAccount() {
  if (!agreePolicy.value) {
    notify(
      "orange",
      "Please check that you have read and agree to the privacy policy."
    );
    return;
  }
  isLoading.value = true;
  const payload = {
    first_name: first_name.value,
    last_name: last_name.value,
    email: email.value,
    mobile_number: mobile_number.value
  };

  if (store.state.affiliate.code != null) {
    payload.code = store.state.affiliate.code;
  }

  store.dispatch("register", payload).then((response) => {
      if (response.status == "success") {
        localStorage.setItem("verification_email", response.email);
        isLoading.value = false;
        router.push("/verification");
      }
      isLoading.value = false;
    })
    .catch(() => {
      isLoading.value = false;
    });
  return payload;
}
</script>
<style scoped>
.cb-form {
  max-width: 500px;
}
a {
  font-size: 14px;
  color: var(--color-primary);
  font-weight: 500;
}
a:hover {
  text-decoration: underline;
}
</style>
