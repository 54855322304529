<template>
  <div class="container ip-cont" style="min-width: 300px">
    <q-form v-if="questionaire.length > 0 && !isSent" @submit="sendFeedback">
      <section>
        <div class="q-mb-md">
          <h4 class="text-accent"> <q-icon name="fa-solid fa-comment"/> Feedback Form</h4>
          <span class="text-h6">Thank you so much for taking the time to answer this form.<br>Let's use the star system of 1 star being the worst and 5 being the best!
          <br>If you have any comments and suggestions we would appreciate it!</span>
        </div>
      </section>
      <div class="full-input q-my-md">
        <q-card
          class="my-card q-mb-lg" 
          flat bordered
        >
          <q-card-section>
            <div class="row no-wrap items-center">
              <div class="col text-h6 q-my-sm">
                Where did you primarily find out about Carbnb?
              </div>
            </div>
            <q-select
              filled
              v-model="findOutModel"
              :options="findOutOptions"
              label="Pick your answer"
              emit-value
              map-options
            />
          </q-card-section>
        </q-card>
        <q-card 
          class="my-card q-mb-lg" 
          flat bordered
          v-for="(questionItem, ind) in questionaire"
          :key="ind">
          <q-card-section>
            <div class="row no-wrap items-center">
              <div class="col text-h6">
                {{ questionItem.question }}
              </div>
            </div>

            <q-rating
              v-model="questionItem.rating"
              size="2em"
              :max="5"
              class="text-accent q-my-md"
              :disable="isLoading"
            />
            <q-input
              v-model="questionItem.comment"
              filled
              autogrow
              type="textarea"
              placeholder="Add comment..."
              class="q-mb-sm"
              counter
              :maxlength="500"
              :disable="isLoading"
            />
          </q-card-section>
        </q-card>
        
        <q-btn 
          color="primary"
          class="btn-primary q-my-md"
          icon-right="send"
          :loading="isSending"
          type="submit"
          label="Submit Feedback"
        />
      </div>
    </q-form>
    <section v-if="isLoading">
      <div class="q-mb-md text-center">
        <h4 class="text-accent">For a while..</h4>
        <span class="text-h6">Please wait while we get your feedback form.</span>
      </div>
      <q-linear-progress indeterminate rounded color="negative" class="q-mt-sm" />
    </section>
    <section v-if="isSent">
      <div class="q-mb-md text-center">
        <h1 class="text-positive q-my-xl"><q-icon name="fa-solid fa-circle-check"/></h1>
        <h4 class="text-accent">Thank you for your feedback!</h4>
        <span class="text-h6">Your feedback will help us continue to improve our services. Once again, thank you for choosing Carbnb.</span>
        <br/>
        <q-btn
          class="q-my-xl"
          color="primary"
          size="md"
          label="Go to home"
          style="width: 250px"
          to="/"
        />
      </div>
    </section>
    <section v-if="isLocked">
      <div class="q-mb-md text-center">
        <h1 class="text-positive q-my-xl"><q-icon name="fa-solid fa-circle-check"/></h1>
        <h4 class="text-accent">Already Finalized!</h4>
        <span class="text-h6">Thank you for revisiting the feedback form. This feedback form is already finalized by the management and is now locked form being viewed and edited.<br><br>Thank you so much for your feedback!</span>
        <br/>
        <q-btn
          class="q-my-xl"
          color="primary"
          size="md"
          label="Okay"
          style="width: 250px"
          to="/"
        />
      </div>
    </section>
    <br>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { notify } from "../utils/notification";
import { useRoute, useRouter } from "vue-router";

const store = useStore();
const isLoading = ref(true);
const isSending = ref(false);
const isSent = ref(false)
const isLocked = ref(false)
const router = useRouter();
const route = useRoute();
const urlCode = route.query.id;
const feedback = ref([]);
const questionaire = ref([]);
const generalError = ref('');
const findOutOptions = ref();
const findOutModel = ref();

function validateFeedbackForm(){
  if (!urlCode) {
      notify(
        "negative",
        "Feedback URL not valid",
        "",
        3000
      );
    router.push('/');
    return;
  }

  store.dispatch("validateFeedbackForm", urlCode).then((r) => {
    if (r.status == "success") {
      feedback.value = r.data.feedback;
      const realVal = decodeHtml(feedback.value.value);
      questionaire.value = JSON.parse(realVal);
      findOutOptions.value = r.data.find_out_options;
    } else if (r.status == 'form_locked') {
      isLocked.value = true;
      isSent.value = false;
      isLoading.value = false;
    } else {
      notify(
        "negative",
        "Feedback URL not valid",
        "",
        3000
      );
      router.push('/');
    }
    
    isLoading.value = false;
  }).catch((error) => {
    if (error.response.status === 404) {
      notify("negative", "Feedback URL not valid","",3000);
      router.push('/');
    }
    else if (error.response !== undefined) {
      notify("negative", "There has been an error. Please try again later.");
    } else {
      console.error(error);
    }
  }).finally(() => {
    isLoading.value = false;
  });
}

function sendFeedback() {
  generalError.value = '';
  isSending.value = true;

  const payload = {
    ...feedback.value,
    value: JSON.stringify(questionaire.value),
    findOut: findOutModel.value
  };

  store.dispatch("sendFeedbackForm", payload).then((r) => {
    if (r.status == "success") {
      isSent.value = true;
      notify(
        "positive",
        "Thank you for your feedback!",
        "",
        3000
      );
    }
    
    isSending.value = false
  }).catch((error) => {
    if (error.response !== undefined) {
          notify("negative", "There has been an error. Please try again later.");
    } else {
      console.error(error);
    }
  }).finally(() => {
    validateFeedbackForm();
    isSending.value = false;
  });
}

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

validateFeedbackForm();
</script>
<style scoped>
.full-input {
  width: 100%;
  margin-bottom: 10px;
}
.full-input.flex .q-input {
  max-width: 48%;
}
</style>
