import axios from "axios";
import invoiceTypes from "../utils/payment/invoiceTypes";
import { notify } from "@/utils/notification";
import paymentResponses from "../utils/payment/paymentResponses";
import paymentMethods from "../utils/payment/paymentMethods";
import { useRouter } from "vue-router";

// Set up router
const router = useRouter();

// Create an axios instance for paymentHttp since Paymongo will have its
// own Authorization header.
const paymentHttp = axios.create({
  baseURL: process.env.VUE_APP_APISERVER
});

// Set the authorization header
paymentHttp.defaults.headers.common["Authorization"] = 'Basic ' + btoa(process.env.VUE_APP_PM_PUBLIC_KEY);

// Set the PAPI-Key
paymentHttp.defaults.headers.common["X-Papi-Key"] = process.env.VUE_APP_PAPI_KEY;

// Set the current version and platform
paymentHttp.defaults.headers.common["X-AppPlatform"] = process.env.VUE_APP_BUILD_TARGET;

// If the API is tunnelled through an ngrok endpoint.
paymentHttp.defaults.headers.common["ngrok-skip-browser-warning"] = true;

if (process.env.VUE_APP_BUILD_TARGET == 'mobile') {
  paymentHttp.defaults.headers.common['X-AppVer'] = btoa(process.env.VUE_APP_VERSION);
}

// Set the interceptor
paymentHttp.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status == 400) {
      if (error.response.data.status == 'update_required') {
        console.error('You are using an outdated version of the app. The system requires you to update to use its services.');
        notify("warning", "Please update your app to the latest version to proceed with payments.", "", 5000);
        router.push('/');
      }
    }
  }
)
const payment = {
  state: () => ({
    invoice: null,
    paymentIntentId: null
  }),

  getters: {
    //
  },

  actions: {
    /**
     * Retrieves the payment details of the booking from the server and
     * returns the invoice.
     *
     * @param { Object } param0 
     * @param { Object } payload Must contain the booking code and surname.
     * @returns 
     */
    getPaymentDetails({ commit }, payload) {
        return new Promise((resolve, reject) => {
          paymentHttp({
            url: `/api/v1/payments?booking_code=${payload.booking_code}&surname=${payload.surname}`,
            method: "GET"
          }).then((response) => {
            if (response.data.status == "payment_request_retrieved") {
              const invoice = response.data.data.invoice;
              commit("setInvoice", invoice)
              return resolve({
                status: response.data.status,
                invoice: invoice
              })
            } else if (response.data.status == "payment_already_received") {
              commit("setInvoice", null)
              return resolve({
                status: response.data.status
              })
            }
          }).catch((error) => {
            if (error.response) {
              return reject({
                code: error.response.status,
                status: error.response.data.status,
                data: error.response.data.data
              })
            } else {
              return reject({status: 'error'})
            }
          });
        });
    },

    /**
    * Initializes the payment when the client has choose to either pay from
    * Card or Gcash.
    *
    * @param { Object } param0 
    * @param { Object } payload 
    */   
    initializePayment({state, commit}, paymentType) {
        let invoice = null;
        if (state.invoice.type != invoiceTypes.booking) {
            invoice = btoa(state.invoice.extensionInvoice.invoice_number + ':' + state.invoice.booking_id);
        } else {
            // If this is for the booking reservation, send the origin invoiceNumber instead of the extension invoice.
            invoice = btoa(state.invoice.invoice_number + ':' + state.invoice.booking_id)
        }

        return new Promise((resolve, reject) => {
          paymentHttp({
              method: 'POST',
              url: '/api/v1/payments/initialize-payment',
              data: {
                invoice: invoice,
                type: paymentType,
                origin: 'app'
              }
            }).then((response) => {
              // Retrieve the status
              var status = response.data['status'];
              
              // If the client chooses to pay thru intent (card)
              if (paymentType === paymentMethods.card) {
                if (status == paymentResponses.paymentIntentCreated) {
                  // TODO:
                  commit('updatePaymentIntentId', response.data['data']['payment_intent_id']);
    
                  return resolve({
                    status: paymentResponses.paymentIntentCreated,
                    paymentIntentId: state.paymentIntentId
                  });
                } else if (status == paymentResponses.alreadyPaid) {
                  return resolve({
                    status: paymentResponses.alreadyPaid
                  });
                }
              }
    
              // If the client chooses to pay thru source (source)
              if (paymentType === paymentMethods.gcash) {
                if (status === paymentResponses.paymentSourceCreated) {
                  return resolve({
                    status: paymentResponses.pending,
                    checkoutUrl: response.data['data']['checkoutUrl'],
                    successUrl: response.data['data']['successUrl'],
                    failedUrl: response.data['data']['failedUrl']
                  });
                }
                // TODO: Handle the already paid scenario
                if (status === paymentResponses.alreadyPaid) {
                  return resolve({
                    status: paymentResponses.alreadyPaid
                  });
                }
              }
            }).catch((err) => {
              return reject(err)
            });
        });
    },

    /**
     * Creates a payment method.
     *
     * @param { Object} payload 
     */
    createPaymentMethod(_, payload) {
      return new Promise((resolve, reject) => {
        paymentHttp({
          method: 'POST',
          url: 'https://api.paymongo.com/v1/payment_methods',
          data: {
            data: {
              attributes: {
                details: {
                  card_number: payload.cardDetails.number,
                  exp_month: Number(payload.cardDetails.expMonth),
                  exp_year: Number(payload.cardDetails.expYear),
                  cvc: String(payload.cardDetails.securityCode)
                },
                type: payload.paymentType
              }
            }
          }
        }).then((response) => {
          return resolve({
            status: 'card_submitted',
            paymentMethodId: response.data['data']['id']
          });
        }).catch((err) => {
          return reject(err);
        })
      });
    },

    /**
     * Attaches the created payment method to the created payment intent.
     *
     * This is the actual payment process. The app will
     * send an attempt to API and the API will send an
     * attempt to the gateway.
     * @param {*} state
     * @param { Object } payload 
     */
    attachPaymentMethod({state}, payload) {
      let invoice = null;
      if (state.invoice.type != invoiceTypes.booking) {
          invoice = btoa(state.invoice.extensionInvoice.invoice_number + ':' + state.invoice.booking_id);
      } else {
          // If this is for the booking reservation, send the origin invoiceNumber instead of the extension invoice.
          invoice = btoa(state.invoice.invoice_number + ':' + state.invoice.booking_id)
      }
      return new Promise((resolve, reject) => {
        paymentHttp({
          method: 'POST',
          url: '/api/v1/payments',
          data: {
            invoice: invoice,
            paymentIntentId: payload.paymentIntentId,
            paymentMethodId: payload.paymentMethodId
          }
        }).then((response) => {
          var status = response.data['status'];
          // Payment already fulfilled
          if (status === 'payment_fulfilled') {
            return resolve({
              status: 'payment_fulfilled'
            });
          }

          // Payment already paid
          if (status === 'already_paid') {
            return resolve({
              status: 'already_paid'
            });
          }

          // If the system needs authentication verification
          if (status === paymentResponses.authenticationRequired) {
            return resolve({
              status: paymentResponses.authenticationRequired,
              url: response.data['data']['url']
            });
          }

          // If the system payment has been successfully made
          if (status === paymentResponses.succeeded) {
            return resolve({
              status: paymentResponses.succeeded
            });
          }

          // Failure - reset the payment steps
          if (status === paymentResponses.paymentFailed) {
            return resolve({
              status: paymentResponses.paymentFailed,
              message: response.data['data']['last_payment_error']
            });
          }

          // Still under process - needs a requery
          if (status === paymentResponses.processing) {
            return resolve({
              status: paymentResponses.processing
            });
          }
        }).catch((err) => {
          return reject(err);
        })
      });      
    },
  
    /**
     * The continuation call of the payment intent method after the client authenticates their
     * card.
     *
     * @param { Object } param0
     */
    continue({state}) {
      let invoice = null;
      if (state.invoice.type != invoiceTypes.booking) {
          invoice = btoa(state.invoice.extensionInvoice.invoice_number + ':' + state.invoice.booking_id);
      } else {
          // If this is for the booking reservation, send the origin invoiceNumber instead of the extension invoice.
          invoice = btoa(state.invoice.invoice_number + ':' + state.invoice.booking_id)
      }

      return new Promise((resolve, reject) => {
        paymentHttp({
          method: 'POST',
          url: '/api/v1/payments/continue-payment',
          data: {
            invoice: invoice
          }
        }).then((response) => {
          var status = response.data['status'];

          return resolve({status: status});
        }).catch((err) => {
          return reject(err);
        })
      });
    },
  
    /**
     * Checks the invoice payment_source_id for 
     * @param { Object } param0 
     */
    checkSource({state}) {
      // If this invoice has attached extension that needs to be paid, we need to instead
      // submit the invoice number of the extension.
      let invoiceNumber = null;
      if (state.invoice.type != invoiceTypes.booking) {
        invoiceNumber = state.invoice.extensionInvoice.invoice_number;
      } else {
        // If this is for the booking reservation, send the origin invoiceNumber instead of the extension invoice.
        invoiceNumber = state.invoice.invoice_number;
      }

      return new Promise((resolve, reject) => {
        paymentHttp({
          method: 'GET',
          url: '/api/v1/payments/get-source?invoice_number=' + invoiceNumber + '&booking_id=' + state.invoice.booking_id,
        }).then((response) => {
          var status = response.data['status'];

          // If the system needs authentication verification
          if (status === paymentResponses.chargeable) {
            return resolve({status: paymentResponses.chargeable});
          }
          if (status === paymentResponses.pending) {
            return resolve({status: paymentResponses.pending});
          }

          return resolve({status: status});
        }).catch((err) => {
          return reject(err);
        })
      });
    },

    /**
     * Process the transaction of e-wallet payment.
     *
     * @param { Object } param0 
     */
    transact({state}) {
      // If this invoice has attached extension that needs to be paid, we need to instead
      // submit the invoice number of the extension.
      let invoice = null;
      if (state.invoice.type != invoiceTypes.booking) {
        invoice = btoa(state.invoice.extensionInvoice.invoice_number + ':' + state.invoice.booking_id);
      } else {
        // If this is for the booking reservation, send the origin invoiceNumber instead of the extension invoice.
        invoice = btoa(state.invoice.invoice_number + ':' + state.invoice.booking_id)
      }
      return new Promise((resolve, reject) => {
        paymentHttp({
          method: 'POST',
          url: '/api/v1/payments/transact',
          data: {
            invoice: invoice
          }
        }).then((response) => {
          var status = response.data['status'];

          return resolve({status: status});

        }).catch((err) => {
          return reject(err);
        })
      });
    },
  },

  mutations: {
    /**
     * Sets the invoice for this app.
     *
     * @param { Object } state
     * @param { Object } invoice 
     */
    setInvoice(state, invoice) {
        state.invoice = invoice;
    },
    /**
     * Updates the current payment intent id stored on the app.
     *
     * @param { Object } state 
     * @param { String } value 
     */
    updatePaymentIntentId(state, value) {
      state.paymentIntentId = value;
    },
  },
  namespaced: true,
};

export default payment;
