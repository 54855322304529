import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import booking from "@/store/booking.js";
import user from "@/store/user.js";
import operator from "@/store/operator.js";
import vehicle from "@/store/vehicle.js";
import qr from "@/store/qr.js";
import payment from "@/store/payment.js";
import invoice from "@/store/invoice.js";
import { notify } from "../utils/notification";
import affiliate from "@/store/affiliate";

export default createStore({
  state: {
    isLoggedIn: false,
    openLoginForm: false,
    displayFooter: true,
    leftDrawerOpen: false,
  },

  getters: {},

  actions: {
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/v1/users/sign-in",
          method: "POST",
          data: payload,
        }).then((response) => {
            if (response.data.status == "signed_in") {
              const token = response.data.data.token;
              localStorage.setItem("user_loggedin_token", token);
              localStorage.setItem("user_loggedin_id", response.data.data.user.id);
              axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.data.user.id} ${token}`;
              commit("setisLoggedIn", true);
              return resolve({
                status: "success",
                data: response.data.data,
              });
            }

            if (response.data.status == 'has_temp_pass') {
              notify("warning", "Please check your email for your temporary password that we sent you. If you cannot find it, please click I forgot my password.");
              return resolve({ status: "incorrect_temp_pass" });
            }

            if (response.data.status == 'unverified_email') {
              notify("warning", "You need to verify this email address. Please try checking your inbox for the verification code. If you cannot find the code, please Resend Code.");
              return resolve({ status: "unverified_email" });
            }
          })
          .catch((err) => {
            notify("negative", "The email or password you entered is incorrect. Please try again.");
            return reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("user_loggedin_token");
        localStorage.removeItem("verification_user_id");
        localStorage.removeItem("verification_user_email");

        delete axios.defaults.headers.common["Authorization"];

        return resolve("logged_out");
      });
    },
    register(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/api/v1/users",
          method: "POST",
          data: payload,
        }).then((response) => {
          const data = response.data;
          if (data && data.status == "user_created") {
            return resolve({
              status: "success",
              id: data.data.id,
              email: payload.email,
            });
          } else if (data && data.status == "has_temporary_password") {
            notify("warning", "This email is pre-registered and have a temporary password. Please check your email inbox for the temporary password. If you cannot find it, please click 'I forgot my password'.");

            return resolve({status: response.status });
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 400) {
              if (err.response.data.status == 'email_not_available') {
                notify("negative", "The email address that you entered is not available since it is being used by other clients.");
              }
            }
          }
          return reject(err);
        });
      });
    },
    verify(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/verify`,
          method: "POST",
          data: payload,
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        })
      });
    },
    resendVerification(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/users/resendverification`,
          method: "POST",
          data: payload,
        }).then((response) => {
          return resolve(response);
        }).catch((err) => {
          return reject(err);
        })
      });
    },
    forgotPassword(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: "/api/v1/users/forgotpassword",
          method: "POST",
          data: payload,
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
          if (response.status == 403) {
            return resolve({
              status: "email_unavailable",
            });
          }
        });
      });
    },
    confirmForgotNewPassword(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: "/api/v1/users/forgotpassword/confirm",
          method: "POST",
          data: payload,
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
          if (response.status == 403) {
            return resolve({
              status: "email_unavailable",
            });
          }
        });
      });
    },
    sendInquiry(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: "/api/v1/inquiries",
          method: "POST",
          data: payload,
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
          if (response.status == 403) {
            return resolve({
              status: "email_unavailable",
            });
          }
        });
      });
    },
    validateFeedbackForm(_, urlCode) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/feedbacks/validate/${urlCode}`,
          method: "GET",
        })
          .then((response) => {
            if (response.data.status == "success") {
              const feedbacks = response.data.data;

              return resolve({
                status: "success",
                data: feedbacks
              });
            } else {
              return resolve({
                status: response.data.status
              });
            }
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    sendFeedbackForm(_, payload) {
      return new Promise((resolve) => {
        axios({
          url: "/api/v1/feedbacks",
          method: "PUT",
          data: payload,
        }).then((response) => {
          if (response.data.status == "success") {
            return resolve({
              status: "success",
            });
          }
        });
      });
    },
  },

  mutations: {
    setisLoggedIn: (state, status) => {
      state.isLoggedIn = status;
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
    setToggleDrawer: (state) => {
      state.leftDrawerOpen = !state.leftDrawerOpen;
    },
  },
  modules: {
    user: user,
    booking: booking,
    vehicle: vehicle,
    operator: operator,
    qr: qr,
    payment: payment,
    invoice: invoice,
    affiliate: affiliate
  },
  plugins: [createPersistedState()],
});
