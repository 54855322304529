<template>
  <div class="container profileDashboard">
    <ProfileSidebar />
    <section>
      <h5 class="text-accent"><q-icon name="settings"/> Settings</h5>
      <q-separator class="q-my-md" />
      <div class="q-pl-sm">
        <div class="flex justify-between items-center">
          <div>
            <h6 class="text-accent">Change Password</h6>
            <p>It is advised to change your password regularly.</p>
          </div>
          <q-btn
            label="Change"
            :class="'autoWidth maxWidth200'"
            :dense="true"
            icon="edit"
            v-if="!resetPassword"
            color="primary"
            @click="resetPassword = true"
          />
        </div>
        <ChangePassword
          v-if="resetPassword"
          @cancelPasswordReset="cancelPasswordReset"
        />
      </div>
    </section>
  </div>
</template>
<script setup>
import ProfileSidebar from "../components/Profile/ProfileSidebar.vue";
import ChangePassword from "../components/Settings/ChangePassword.vue";
import { ref } from "vue";

const resetPassword = ref(false);
const resetPasswordRequestSent = ref(false);

function cancelPasswordReset() {
  resetPassword.value = false;
  resetPasswordRequestSent.value = false;
}
</script>
<style scoped>
.btn-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.maxWidth100 {
  width: 100px;
}
@media screen and (max-width: 768px) {
  .btn-password {
    display: inline;
  }
}
</style>
