<template>
    <q-dialog
      v-model="isShown"
      persistent
      :maximized="true"
    >
      <q-card class="column bg-white text-black full-height full-width justify-center items-center content-center">
        <q-card-section class="text-center">
            <div class="q-py-md">
                <q-img
                    src="@/assets/images/carbnb-logo-transparent.webp"
                    style="max-width: 200px"
                />
            </div>
            <p class="text-subtitle2" v-if="!isOTPSent">We will send a 6 digit PIN to your mobile number (+63){{ user.mobile_number }}</p>
            <p class="text-subtitle2" v-if="isOTPSent">We have sent a 6 digit PIN to (+63){{ user.mobile_number }}.<br>Please enter it on the field below and click Verify.</p>
            <q-input
                v-if="isOTPSent"
                v-model="code"
                outlined
                class="cb-input"
                :error="errorMessage != false"
                :error-message="errorMessage"
            />
        </q-card-section>
        <q-card-footer class="text-center">
          <q-btn class="btn-primary" v-if="isOTPSent" color="positive" :loading="isSendingOtp" label="Verify" @click="verifyOtp" icon-right="send"/>
          <q-btn class="btn-primary" v-if="!isOTPSent" color="primary" label="Send Code"
            @click="sendOtp" :loading="isSendingOtp" icon-right="send"/>
          <q-btn class="btn-primary" color="secondary" label="Back" @click="closeDialog" icon="arrow_left"/>
        </q-card-footer>
      </q-card>
    </q-dialog>
</template>
<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { notify } from "@/utils/notification";
import axios from "axios";

export default {
    setup(_, {emit}) {
        const store = useStore();
        const user = computed(() => store.state.user.data);
        const isOTPSent = ref(false);
        const isSendingOtp = ref(false);
        const otpId = ref(null);
        const errorMessage = ref(false);
        const code = ref();

        function sendOtp()
        {
            isSendingOtp.value = true;
            axios({
                url: '/api/v1/otp/send',
                method: "POST",
            }).then((r) => {
                isOTPSent.value = true;
                otpId.value = r.data.data['otp_id'];
                isSendingOtp.value = false;
            }).catch((error) => {
                if (error.response) {
                   notify('warning', error.response.data.data.message);
                }
                if (error.response.data.status === 'interval_required') {
                    // Disable the button momentarily to prevent spamming.
                    setTimeout(() => {
                        isSendingOtp.value = false;
                    }, error.response.data.data.interval * 1000);
                }
            });
        }

        function verifyOtp()
        {
            isSendingOtp.value = true;
            axios({
                url: '/api/v1/otp/verify',
                method: "POST",
                data: {
                    code: code.value,
                    otp_id: otpId.value
                }
            }).then(() => {
                store.commit('user/setMobileNumberVerifiedDate', true);
                notify('positive', 'You successfully verified your mobile number.');
                closeDialog();
            }).catch((error) => {
                isSendingOtp.value = false;
                if (error.response) {
                   errorMessage.value = error.response.data.data['message'];
                }
            });
        }

        function closeDialog()
        {
            isOTPSent.value = false;
            isSendingOtp.value = false;
            otpId.value = null;
            emit('closeDialog');
        }

        return {
            isSendingOtp,
            isOTPSent,
            user,
            sendOtp,
            closeDialog,
            errorMessage,
            otpId,
            code,
            verifyOtp
        }
    },
    props: {
        shown: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        isShown() {
            return this.shown;
        }
    }
}
</script>