<template lang="">
  <center>
    <q-carousel
      animated
      v-model="slide"
      arrows
      navigation
      infinite
      height="200px"
      :thumbnails="true"
      autoplay
    >
      <q-carousel-slide class="bg-size-cover" :name="1" :img-src="require('@/assets/images/banners/default.png')" v-if="!isLoaded"/>
      <q-carousel-slide class="bg-size-cover" :name="banner.id" :img-src="banner.url" v-else v-for="banner in banners" v-bind:key="banner.id"/>
    </q-carousel>
  </center>
</template>
<script setup>
import { ref } from 'vue'
import axios from "axios";

const slide = ref(1)
const isLoaded = ref(false);
const banners = ref([]);

axios({
  url: `/api/v1/banners`,
  method: "GET"
}).then((response) => {
  var data = response.data.data.banners;
  if (data.length > 0) {
    isLoaded.value = true;
    banners.value = data;
    console.log(banners.value)
  }
}).catch((err) => {
  console.error(err)
});
</script>