<template>
    <q-dialog
      v-model="isShown"
      position="bottom"
    >
      <q-card class="column bg-white text-black full-height full-width justify-center content-center">
        <q-card-section class="row q-pb-none">
          <q-space />
          <q-btn icon="close" flat round dense @click="$emit('close-mobile-ads')" />
        </q-card-section>
        <q-card-section class="text-center">
            <div class="q-py-md">
                <q-img
                    src="@/assets/images/carbnb-logo-transparent.webp"
                    style="max-width: 100px"
                />
            </div>
            <p class="text-subtitle2">Hi there ka-byaheros! Our revamped mobile app is now available on {{ platform }}. You can click the badge below to download!</p>
            <p>{{errorMessage}}</p>
            <a v-if="platform == 'Google Play'" target="_blank" href="market://details?id=com.cbrentals.carbnb">
                <q-img
                    src="@/assets/images/badge-play-store.png"
                    style="max-width: 150px"
                />
            </a>
            <a v-else target="_blank" href="https://itunes.apple.com/app/id6469505708">
                <q-img
                    src="@/assets/images/badge-app-store.jpg"
                    style="max-width: 150px"
                />
            </a>
        </q-card-section>
      </q-card>
    </q-dialog>
</template>
<script>
export default {
   created() {
    //
   },
   props: {
    shown: {
        type: Boolean,
        default: false
    },
    platform: {
        type: String,
        default: ''
    }
   },
   computed: {
    isShown() {
        return this.shown;
    }
   }
}
</script>